import { Box } from '@mui/material';
import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TicketTable } from '~/components/tickets/TicketTable';
import { getFilterModelFromQueryParams, useTickets } from '~/data/hooks/useTickets';
import { useGridFilterModel } from '~/hooks/useGridFilterModel';

const defaultSortModel: GridSortModel = [{ field: 'startedTime', sort: 'desc' }];

type PropertyTicketsProps = {
  propertyId: string;
};

export function PropertyTickets({ propertyId }: PropertyTicketsProps) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [searchParams] = useSearchParams();

  const filterModelFromQueryParams = getFilterModelFromQueryParams(searchParams);

  const { filterModel, setFilterModel } = useGridFilterModel({
    initialState: filterModelFromQueryParams,
    quickFilterPlaceHolder: 'Search Tickets...',
  });

  const { tickets, numTickets, isLoading, onExport } = useTickets({
    propertyId,
    paginationModel,
    filterModel,
    sortModel,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <TicketTable
        tickets={tickets}
        numTickets={numTickets}
        isLoading={isLoading}
        onExport={onExport}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        paginationModel={paginationModel}
        filterModel={filterModel}
        sortModel={sortModel}
        hiddenColumns={['property', 'portfolio']}
        enableQuickFilter
      />
    </Box>
  );
}
