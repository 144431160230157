import {
  isTicketAutoUpload,
  type Ticket,
  type TicketWithPortfolio,
} from '@liftai/asset-management-types';
import { Delete, Edit } from '@mui/icons-material';
import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-premium';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { UserContext } from '~/auth/userContext';
import { AIIcon } from '~/components/icons';
import LiftAITable from '~/components/table';
import type { TicketExpandableRowProps } from '~/components/table/expandableRow';
import { TicketExpandableRow } from '~/components/table/expandableRow';
import { useDeleteEntityDialog } from '~/components/tickets';
import AddTicketDialog from '~/components/uploadDocument/AddTicketDialog';
import { mapTicketToFormValues } from '~/components/uploadDocument/TicketForm';
import useTicket from '~/data/hooks/useTicket';
import { useGridColumns } from '~/hooks/useGridColumns';
import { useGridExporter } from '~/hooks/useGridExporter';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { ACTIONS_ON_TICKETS, canAccess, NAVIGATE_TO_PROPERTY_PAGE } from '~/utils/role';

import { getTicketsTableColumns } from './ticketTableColumns';

const defaultSortModel: GridSortModel = [{ field: 'startedTime', sort: 'desc' }];

export type TicketTableProps = {
  tickets: TicketWithPortfolio[];
  numTickets: number;
  isLoading: boolean;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onFilterModelChange: (model: GridFilterModel) => void;
  onExport: () => Promise<TicketWithPortfolio[]>;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  hiddenColumns?: string[];
  enableQuickFilter?: boolean;
};

export function TicketTable({
  tickets,
  numTickets,
  isLoading,
  onExport,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
  paginationModel,
  filterModel,
  sortModel,
  hiddenColumns = [],
  enableQuickFilter,
}: TicketTableProps) {
  const { showEntityActionSnackbar } = useLAISnackbar();
  const [gridAction, setGridAction] = useState<{
    actionName: 'actionEdit' | 'actionDelete';
    ticketId: string;
  } | null>(null);
  const [searchParams] = useSearchParams();
  const apiRef = useGridApiRef();

  const {
    userDetails: { role: userRole },
  } = useContext(UserContext);

  const { ticket: ticketToEdit, deleteTicket } = useTicket(gridAction ? gridAction.ticketId : null);
  const ticketFormData = useMemo(
    () => (ticketToEdit ? mapTicketToFormValues(ticketToEdit) : null),
    [ticketToEdit],
  );

  const clearGridAction = useCallback(() => {
    setGridAction(null);
  }, []);

  const { dialog: deleteDialog, openForEntity: openDeleteDialogForTicket } = useDeleteEntityDialog({
    onDelete: async (entity: Ticket) => {
      await deleteTicket(entity.id);

      showEntityActionSnackbar(
        {
          name: 'Ticket',
          action: ActionEnum.Delete,
          id: entity.number,
        },
        {
          variant: 'info',
        },
      );
    },
    entityName: 'Ticket',
  });

  const { GridToolbarExport } = useGridExporter<TicketWithPortfolio>({
    fetcher: onExport,
    apiRef,
  });

  const { GridToolbarColumnsButton, columnVisibilityModel } = useGridColumns({
    storeKey: 'tickets-table-columns-visibility',
    initialVisibility: {
      id: false,
      finishedTime: false,
      callTime: false,
      timeOnSiteRegularHours: false,
      timeOnSiteOvertime: false,
      travelTimeRegularHours: false,
      travelTimeOvertime: false,
      ...Object.fromEntries(hiddenColumns.map((col) => [col, false])),
    },
  });

  const getActionsEl = useCallback(
    (ticket: Ticket): JSX.Element[] => {
      const actions: JSX.Element[] = [];

      if (isTicketAutoUpload(ticket)) {
        actions.push(
          <GridActionsCellItem
            key="processed-by-ai"
            title="This ticket was processed by AI and may require verification. Please review for accuracy."
            label="processed by AI"
            icon={<AIIcon />}
            disabled
            // pointerEvents allow the tooltip to show on hover
            style={{ pointerEvents: 'all', padding: 0 }}
          />,
        );
      }

      actions.push(
        ...[
          <GridActionsCellItem
            key="edit"
            icon={<Edit />}
            onClick={() => {
              if (!ticket.id) {
                console.error('Ticket has no ID');
                return;
              }
              setGridAction({ actionName: 'actionEdit', ticketId: ticket.id });
            }}
            label="Edit"
            showInMenu
          />,
          <GridActionsCellItem
            key="delete"
            showInMenu
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              openDeleteDialogForTicket(ticket);
            }}
          />,
        ],
      );

      return actions;
    },
    [openDeleteDialogForTicket],
  );

  const shouldLinkOnProperty = canAccess(userRole, NAVIGATE_TO_PROPERTY_PAGE);
  const canEdit = canAccess(userRole, ACTIONS_ON_TICKETS);
  const ticketListCols = useMemo(
    () =>
      getTicketsTableColumns({
        linkOnProperty: shouldLinkOnProperty,
        canEdit,
        getActionsEl,
      }),
    [shouldLinkOnProperty, canEdit, getActionsEl],
  );

  const expandedRowId = searchParams.get('ticketId');

  const initialState = {
    pinnedColumns: { right: ['actions'] },
    columns: {
      columnVisibilityModel,
    },
    sorting: {
      sortModel: defaultSortModel,
    },
    detailPanel: expandedRowId
      ? {
          expandedRowIds: [expandedRowId],
        }
      : undefined,
  };

  return (
    <>
      <LiftAITable<TicketWithPortfolio>
        rows={tickets}
        apiRef={apiRef}
        rowCount={numTickets}
        loading={isLoading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        disableRowGrouping
        filterMode="server"
        filterModel={filterModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        CustomGridToolbarExport={GridToolbarExport}
        CustomGridToolbarColumnsButton={GridToolbarColumnsButton}
        onFilterModelChange={onFilterModelChange}
        filterToPreserve={['startedTime', 'portfolio']}
        columns={ticketListCols}
        getDetailPanelContent={({ row }: TicketExpandableRowProps<TicketWithPortfolio>) => {
          return <TicketExpandableRow row={row} />;
        }}
        tableHeaderTitle={'Tickets'}
        initialState={initialState}
        enableQuickFilter={enableQuickFilter}
      />
      {ticketFormData && canAccess(userRole, ACTIONS_ON_TICKETS) ? (
        <AddTicketDialog
          open
          onClose={clearGridAction}
          onSubmit={clearGridAction}
          isEdit
          defaultValues={ticketFormData}
        />
      ) : null}

      {canAccess(userRole, ACTIONS_ON_TICKETS) && deleteDialog}
    </>
  );
}
