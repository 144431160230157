import { z } from 'zod';
export const determineProblemTypesRequest = z.object({
    tickets: z.array(z.object({
        workPerformed: z.string(),
        problemDescription: z.string(),
    })),
});
export const determineEntryTypesRequest = z.object({
    tickets: z.array(z.object({
        problemDescription: z.string(),
        isEmergency: z.string(),
    })),
});
export const determineProblemTypesResponse = z.object({
    results: z.array(z.object({
        problemType: z.string(),
        equipmentRelated: z.string(),
    })),
});
export const determineEntryTypesResponse = z.object({
    results: z.array(z.object({
        entryType: z.string(),
    })),
});
