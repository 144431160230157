import {
  Button,
  type ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  type DialogProps,
  DialogTitle,
} from '@mui/material';
import { useCallback } from 'react';

export interface LAIConfirmationDialogProps extends DialogProps {
  /**
   * Text to be shown on the Cancel button.
   * @default "Cancel"
   */
  cancelButtonText?: string;

  /**
   * Text to be shown on the Accept button.
   * @default "Accept"
   */
  acceptButtonText?: string;

  /** Handler to be called when the user clicks the Accept button. */
  onAccept?: () => void;

  /** Handler to be called when the user clicks the Cancel button. */
  onCancel?: () => void;

  /**
   * A handler called when the Dialog determines that it should be closed.
   *
   * Note: the LAIConfirmationDialog is fully controlled and does not manage its own
   * opened/closed state.
   */
  onClose: () => void;

  /** The title to be shown in the dialog. */
  title: string;

  /**
   * Whether the Dialog should be rendered as open.
   *
   * Note: This component is intended to be fully controlled and not manage its own
   * opened state.
   */
  open: boolean;

  /**
   * The theme-aware color of the Accept Button's text. If the dialog is being used
   * to request consent for a destructive action, this could be set to "error", for
   * example, to signify that the action is dangerous.
   *
   * @default "primary"
   */
  acceptButtonColor?: ButtonProps['color'];

  /**
   * A React Component which wraps the `children` provided to this component. By
   * default this is `DialogContentText`, which opts `children` into MUI's typography
   * styles. You can specify this prop to override the wrapper component or
   * completely replace the body of the Dialog (i.e. by defining a Component here
   * which ignores its children prop).
   *
   * @default {DialogContentText}
   */
  contentComponent?: (props: React.PropsWithChildren) => React.ReactElement;
}

/**
 * A bespoke dialog which wraps MUI's Dialog component and abstracts a confirmation
 * workflow for better reusability.
 */
export default function LAIConfirmationDialog({
  title,
  open,
  acceptButtonText = 'Accept',
  cancelButtonText = 'Cancel',
  acceptButtonColor = 'primary',
  onAccept,
  onCancel,
  onClose,
  contentComponent: DialogContentInner = (props) => <DialogContentText {...props} />,
  children,
  ...dialogProps
}: LAIConfirmationDialogProps) {
  const _onAccept = useCallback(() => {
    onAccept?.();
    onClose();
  }, [onAccept, onClose]);

  const _onCancel = useCallback(() => {
    onCancel?.();
    onClose();
  }, [onCancel, onClose]);

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-content"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent id="dialog-content">
        <DialogContentInner>{children}</DialogContentInner>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined">
          {cancelButtonText}
        </Button>
        <Button onClick={_onAccept} color={acceptButtonColor} variant="contained">
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
