import type { API_TicketDetailResponse } from '@liftai/asset-management-types';
import { useCallback } from 'react';
import type { Fetcher } from 'swr';
import useSWR, { useSWRConfig } from 'swr';
import type { MutationFetcher } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import { getTicketMutator } from '~/data/hooks/utils';
import { getApiClient } from '~/utils/api';

const ticketFetcher: Fetcher<
  API_TicketDetailResponse,
  readonly ['ticket', ticketId: string]
> = async ([_, ticketId]) => {
  const apiClient = getApiClient();
  return await apiClient.tickets.getById(ticketId);
};

const ticketDeleteFetcher: MutationFetcher<void, readonly [key: string], { id: string }> = async (
  [key],
  { arg: { id } },
) => {
  const apiClient = getApiClient();
  await apiClient.invoices.delete(id);
};

const useTicketDelete = () => {
  const { mutate } = useSWRConfig();

  const { trigger } = useSWRMutation(['ticket'], ticketDeleteFetcher);

  const deleteInvoice = useCallback(
    async (ticketId: string) => {
      if (!ticketId) {
        throw new Error('Invoice ID is required to update an invoice');
      }

      await trigger({ id: ticketId }, { revalidate: false });

      void mutate(getTicketMutator(ticketId));
    },
    [mutate, trigger],
  );

  return deleteInvoice;
};

export default function useTicket(ticketId: string | null) {
  const deleteTicket = useTicketDelete();

  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(ticketId ? (['ticket', ticketId] as const) : null, ticketFetcher);

  return { ticket: data, error, loading, deleteTicket };
}
