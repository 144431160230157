import type { Contract, User } from '@liftai/asset-management-types';
import EditIcon from '@mui/icons-material/Edit';
import Mail from '@mui/icons-material/Mail';
import Person from '@mui/icons-material/Person';
import Smartphone from '@mui/icons-material/Smartphone';
import { Button, Grid, Stack, styled, Typography } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';

import { UserContext } from '~/auth/userContext';
import { EditContractDetailsDialog } from '~/components/contracts/EditContractDetailsDialog';
import { HourlyRateTable } from '~/components/contracts/HourlyRateTable';
import { yesOrNo } from '~/components/utils/booleanToText';
import { LiftAITextLink } from '~/components/utils/helpers';
import { METADATA_CUSTOM_DETAILS_KEY } from '~/data/hooks/usePropertyContracts';
import { toLongDateFormat } from '~/utils/format';
import { canAccess, EDIT_CONTRACT } from '~/utils/role';

export type ContractDetailsHeaderProps = {
  contractName: string;
  providerName: string;
};
export const ContractDetailsHeader: FC<ContractDetailsHeaderProps> = ({
  contractName,
  providerName,
}) => {
  return (
    <Typography>
      {contractName} with {providerName}
    </Typography>
  );
};

const InfoTypography = styled(Typography)({ fontSize: '14px', color: 'rgb(0 0 0 / 0.6)' });
const ServiceProviderInfo = ({ provider }: { provider: User | null }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <InfoTypography>
          <Person style={{ verticalAlign: 'sub' }} fontSize="small" /> {provider?.fullName ?? '-'}
        </InfoTypography>
      </Grid>
      <Grid item>
        <InfoTypography>
          <Mail style={{ verticalAlign: 'sub' }} fontSize="small" /> {provider?.email ?? '-'}
        </InfoTypography>
      </Grid>
      <Grid item>
        <InfoTypography>
          <Smartphone style={{ verticalAlign: 'sub' }} fontSize="small" />{' '}
          {provider?.phoneNumber ?? '-'}
        </InfoTypography>
      </Grid>
    </Grid>
  );
};

const CustomContractDetails = ({ customDetails }: { customDetails: [string, string][] }) => (
  <>
    {Array.from({ length: Math.ceil(customDetails.length / 4) }, (_, i) => (
      <Grid container key={i}>
        {customDetails.slice(i * 4, i * 4 + 4).map(([key, value], j) => (
          <Grid item xs={3} key={j}>
            <InfoTypography>{key}</InfoTypography>
            <Typography>{value}</Typography>
          </Grid>
        ))}
      </Grid>
    ))}
  </>
);

export type ContractDetailsProps = {
  contract: Contract;
};
export const ContractDetails: FC<ContractDetailsProps> = ({ contract }) => {
  const {
    userDetails: { role: userRole },
  } = useContext(UserContext);
  const rates = useMemo(
    () => [
      {
        name: 'Travel',
        base: contract.travelHourlyRate,
        plus50: contract.travelHourlyRate15,
        plus70: contract.travelHourlyRate17,
        plus100: contract.travelHourlyRate20,
      },
      {
        name: 'Mechanic',
        base: contract.mechanicHourlyRate,
        plus50: contract.mechanicHourlyRate15,
        plus70: contract.mechanicHourlyRate17,
        plus100: contract.mechanicHourlyRate20,
      },
      {
        name: 'Crew',
        base: contract.teamHourlyRate,
        plus50: contract.teamHourlyRate15,
        plus70: contract.teamHourlyRate17,
        plus100: contract.teamHourlyRate20,
      },
    ],
    [contract],
  );

  const contractAttachment = useMemo(
    () => contract.attachments?.find((attachment) => attachment.isPrimary),
    [contract],
  );
  const summarySheet = useMemo(
    () => contract.attachments?.find((attachment) => attachment.isSummarySheet),
    [contract],
  );

  const [openEdit, setOpenEdit] = useState(false);

  const customDetailsValue = contract.metadata?.find((m) => m.key === METADATA_CUSTOM_DETAILS_KEY);
  const customDetailsEntries: [string, string][] = Object.entries(
    JSON.parse(customDetailsValue?.value ?? '{}'),
  );

  return (
    <Stack marginTop={2} spacing={5}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ServiceProviderInfo provider={contract.serviceProvider.owner} />
        {canAccess(userRole, EDIT_CONTRACT) && (
          <Button
            type="button"
            onClick={() => setOpenEdit(true)}
            color="primary"
            variant="outlined"
            disabled={false}
            startIcon={<EditIcon />}
          >
            edit
          </Button>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <InfoTypography>Contract</InfoTypography>
          {contractAttachment ? (
            <LiftAITextLink
              href={contractAttachment.attachmentUrl}
              target="_blank"
              label="download"
              download
            />
          ) : (
            '-'
          )}
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Summary Sheet</InfoTypography>
          {summarySheet ? (
            <LiftAITextLink
              href={summarySheet.attachmentUrl}
              target="_blank"
              label="download"
              download
            />
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <InfoTypography>Effective since</InfoTypography>
          <Typography>{contract.startDate ? toLongDateFormat(contract.startDate) : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Expiration</InfoTypography>
          <Typography>
            {contract.expirationDate ? toLongDateFormat(contract.expirationDate) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Term</InfoTypography>
          <Typography>{contract.term ? `${contract.term}+ month to month` : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Cars</InfoTypography>
          {/* TODO: add cars to the contract */}
          <Typography>{contract.cars ?? '-'}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <InfoTypography>Type</InfoTypography>
          <Typography>{contract.type ? contract.type : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Cancellation</InfoTypography>
          <Typography>{contract.cancellation ? `${contract.cancellation} days` : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Monthly Pricing</InfoTypography>
          <Typography>{contract.monthlyPrice ? `$${contract.monthlyPrice}` : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Adjustment Rate (yearly)</InfoTypography>
          <Typography>
            {contract.hourlyFeeAdjustmentRate ? `${contract.hourlyFeeAdjustmentRate}%` : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <InfoTypography>Contract Paper</InfoTypography>
          <Typography>{contract.contractPaper ? contract.contractPaper : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Regular Business Hours</InfoTypography>
          <Typography>{contract.businessHours ? contract.businessHours : '-'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Parts & Material Markup</InfoTypography>
          <Typography>
            {contract.partsAndMaterialsMarkup ? `${contract.partsAndMaterialsMarkup}%` : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Phone Monitoring</InfoTypography>
          <Typography>{yesOrNo(contract.phoneMonitoring)}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <InfoTypography>Fire Service</InfoTypography>
          <Typography>{yesOrNo(contract.fireService)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Pressure Test</InfoTypography>
          <Typography>{yesOrNo(contract.pressureTest)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <InfoTypography>Maint. Hours per/Month</InfoTypography>
          <Typography>
            {contract.maintenanceHoursTarget ? `${contract.maintenanceHoursTarget} hours` : '-'}
          </Typography>
        </Grid>
        {/* To completely filled the row, one custom detail is required here. */}
        {customDetailsEntries.length > 0 ? (
          <Grid item xs={3}>
            <InfoTypography>{customDetailsEntries[0][0]}</InfoTypography>
            <Typography>{customDetailsEntries[0][1]}</Typography>
          </Grid>
        ) : null}
      </Grid>
      {/* To show the rest of the custom details if there is more than one element. */}
      {customDetailsEntries.length > 1 && (
        <CustomContractDetails customDetails={customDetailsEntries.slice(1)} />
      )}
      <HourlyRateTable rates={rates} />
      <EditContractDetailsDialog
        defaultValues={contract}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      />
    </Stack>
  );
};
