import { InvoiceKind, InvoiceKindKey } from '@liftai/asset-management-types';
import { Box } from '@mui/material';
import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ToggleButtonGroup from '~/components/common/ToggleButtonGroup';
import { SpendingTable } from '~/components/spending/SpendingTable';
import { useSpending } from '~/data/hooks/useSpending';
import { useGridFilterModel } from '~/hooks/useGridFilterModel';

const defaultSortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];

type PropertySpendingProps = {
  propertyId: string;
};

const spendingViewList = [
  { value: InvoiceKind.Invoice, title: 'Invoices' },
  { value: InvoiceKind.Proposal, title: 'Proposals' },
];

export function PropertySpending({ propertyId }: PropertySpendingProps) {
  const [, setURLSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [spendingView, setSpendingView] = useState<InvoiceKindKey>(InvoiceKind.Invoice);
  const onViewChange = (_: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView === InvoiceKind.Invoice || newView === InvoiceKind.Proposal) {
      setSpendingView(newView);
      setURLSearchParams((prev: URLSearchParams) => {
        prev.set('invoice_kind', newView);
        return prev;
      });
    }
  };

  const { filterModel, setFilterModel } = useGridFilterModel({
    initialState: {
      items: [],
      quickFilterValues: [],
    },
    quickFilterPlaceHolder: `Search ${spendingView === 'invoice' ? 'Invoices' : 'Proposals'}...`,
  });

  const { invoices, numInvoices, isLoading, onExport } = useSpending({
    propertyId,
    paginationModel,
    filterModel,
    sortModel,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box display="flex" justifyContent="space-between">
        <ToggleButtonGroup
          activeView={spendingView}
          viewList={spendingViewList}
          handleChange={onViewChange}
        />
      </Box>
      <SpendingTable
        invoices={invoices}
        numInvoices={numInvoices}
        isLoading={isLoading}
        onExport={onExport}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        paginationModel={paginationModel}
        filterModel={filterModel}
        sortModel={sortModel}
        hiddenColumns={['property', 'portfolio']}
        enableQuickFilter
      />
    </Box>
  );
}
