import { API_NumberFilterParams } from '@liftai/asset-management-types';
import type { GridFilterItem, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { get } from 'lodash-es';

/**
 * A helper function for generating a function which accepts a `GridRenderCellParams`
 * object and returns a value from its `row` which is found at the `path` provided.
 * Uses Lodash's `get()` function inside.
 *
 * For example, if your data object is `{ id: 1, nameInfo: { first: 'John', last:
 * 'Doe' } }` you can pull the `first` and `last` within the nested `nameInfo` object.
 */
export const renderCellHelper =
  (query: string) =>
  ({ row }: GridRenderCellParams) => {
    return get(row, query);
  };

/**
 * Basic filtering implementation for a single column.
 * @param value query string to filter by
 * @returns matched rows
 */
export const getApplyQuickFilterFn = (value: string, query: string) => {
  return (params: { row: unknown }) => {
    const { row } = params;
    const rowValue = get(row, query);
    if (!rowValue) return false;
    return get(row, query).toLowerCase().includes(value.toLowerCase());
  };
};

export const gridFilterItemToNumberFilterParams = (
  items: GridFilterItem[],
): API_NumberFilterParams => {
  if (items.length === 0) {
    return {};
  }

  if (items.some((item) => item.field !== items[0].field)) {
    throw new Error('All filter items must be for the same field');
  }

  return {
    eq: items.find((item) => item.operator === '=')?.value,
    gt: items.find((item) => item.operator === '>')?.value,
    gte: items.find((item) => item.operator === '>=')?.value,
    lt: items.find((item) => item.operator === '<')?.value,
    lte: items.find((item) => item.operator === '<=')?.value,
  };
};
