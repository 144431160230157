import type { GridActionsColDef, GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { get } from 'lodash-es';

import { NestedKeyFilterOperator } from './filterOperators';

type FilterableStringQueryConvenience = { query: string };
type FilterableStringFn<TRow> = { filterableStringFn(row: TRow): string };
type FilterableStringConfig<TRow> = FilterableStringQueryConvenience | FilterableStringFn<TRow>;

function isQueryConvenience<TRow>(
  config: FilterableStringConfig<TRow>,
): config is FilterableStringQueryConvenience {
  return (config as FilterableStringQueryConvenience).query !== undefined;
}

export type LiftAIGridColDef<TRow extends GridValidRowModel> = (
  | GridColDef<TRow>
  | GridActionsColDef<TRow>
) & {
  nested?: {} & FilterableStringConfig<TRow>;
};

interface IColDefTemplateArgs<TRow extends GridValidRowModel> {
  options: {
    autoWidth: boolean;
  };
  cols: LiftAIGridColDef<TRow>[];
}

/**
 * This function is used to create a template for the columns of the table, it is
 * used to map the columns to add LiftAI standard styling and configuration, for
 * example the autoWidth property which makes the table columns fit the width of the
 * table.
 *
 * @returns array of mapped columns
 */
export const colDefTemplate = <TRow extends GridValidRowModel>({
  options: { autoWidth },
  cols,
}: IColDefTemplateArgs<TRow>): LiftAIGridColDef<TRow>[] => {
  return cols.map(({ nested: nestedConfig, ...col }) => {
    const widthOverridden = (col.width || col.maxWidth || col.minWidth) !== undefined;

    /** Style table to LiftAI standards */
    if (autoWidth && !widthOverridden) {
      col.flex = 1;
      col.maxWidth = 400;
    }

    /** Map columns with nested key filters */
    if (nestedConfig) {
      const filterableStringFn = isQueryConvenience(nestedConfig)
        ? (row: TRow): string => String(get(row, nestedConfig.query))
        : nestedConfig.filterableStringFn;

      return {
        ...col,
        filterOperators: [NestedKeyFilterOperator({ filterableStringFn })],
      };
    }

    return col;
  });
};
