import { Contract, ContractSummaryDetails } from '@liftai/asset-management-types';
import { useCallback } from 'react';
import type { Fetcher } from 'swr';
import useSWR from 'swr';
import type { MutationFetcher } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import { getApiClient } from '~/utils/api';

/**
 * The metadata custom details key is the key used to store the custom details in the contract metadata.
 */
export const METADATA_CUSTOM_DETAILS_KEY = 'customDetails' as const;
/**
 * The key used to revalidate the contracts list.
 */
const REVALIDATION_KEY = 'contracts';

const propertyContractsListFetcher: Fetcher<
  Contract[],
  [typeof REVALIDATION_KEY, propertyId: string]
> = async ([_, propertyId]) => {
  const apiClient = getApiClient();
  return await apiClient.contracts.getByPropertyId(propertyId);
};

const contractSummaryDetailsUpdateFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; summaryDetails: ContractSummaryDetails }
> = async (a, { arg }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.updateSummary(arg.contractId, arg.summaryDetails);
};

const contractCustomSummaryDetailsUpdateFetcher: MutationFetcher<
  void,
  string,
  { contractId: string; summaryDetails: { [key: string]: string } }
> = async (a, { arg }) => {
  const apiClient = getApiClient();
  await apiClient.contracts.saveCustomSummary(arg.contractId, arg.summaryDetails);
};

const useSaveSummaryDetails = () => {
  const { trigger } = useSWRMutation(REVALIDATION_KEY, contractSummaryDetailsUpdateFetcher);

  const saveSummaryDetails = useCallback(
    async (contractId: string, data: ContractSummaryDetails) => {
      await trigger({ contractId, summaryDetails: data });
    },
    [trigger],
  );

  return saveSummaryDetails;
};

const useSaveCustomDetails = () => {
  const { trigger } = useSWRMutation(REVALIDATION_KEY, contractCustomSummaryDetailsUpdateFetcher);

  const saveCustomSummaryDetails = useCallback(
    async (contractId: string, data: ContractSummaryDetails['customDetails']) => {
      const summaryDetails =
        data?.reduce(
          (acc, { name, value }) => {
            acc[name] = value;
            return acc;
          },
          {} as { [key: string]: string },
        ) ?? {};

      await trigger({ contractId, summaryDetails });
    },
    [trigger],
  );

  return saveCustomSummaryDetails;
};

export default function usePropertyContracts(propertyId?: string) {
  const { data, error, isLoading } = useSWR(
    propertyId ? [REVALIDATION_KEY, propertyId] : null,
    propertyContractsListFetcher,
    {},
  );
  const saveSummaryDetails = useSaveSummaryDetails();
  const saveCustomDetails = useSaveCustomDetails();

  return {
    contracts: data ?? [],
    saveSummaryDetails,
    saveCustomDetails,
    error,
    isLoading,
  };
}
