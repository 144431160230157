// This strange line is required for TypeScript support https://mui.com/material-ui/about-the-lab/#typescript
import type {} from '@mui/lab/themeAugmentation';
import { LinkProps } from '@mui/material/Link';
import type { Theme } from '@mui/material/styles';
import { alpha, createTheme } from '@mui/material/styles';
import type { AllSystemCSSProperties, ResponsiveStyleValue } from '@mui/system';
import { forwardRef } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';

// This is to define the new variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    secondary: true;
  }
}

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = 'LinkBehavior';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1b56bb',
      light: '#e7f2ff',
      dark: '#09295f',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#ED6C02',
    },
    success: {
      main: '#2E7D32',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      variants: [
        {
          props: { variant: 'secondary' },
          style: {
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
          },
        },
      ],
    },
  },
});

export const selectedColor: (
  theme: Theme,
) => ResponsiveStyleValue<AllSystemCSSProperties['backgroundColor']> = (theme) =>
  alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity);
