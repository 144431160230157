import { Contract } from '@liftai/asset-management-types';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from '@mui/material';
import type { SyntheticEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ContractDetails, ContractDetailsHeader } from '~/components/contracts/ContractDetails';

export type PropertyContractsProps = {
  contracts: Contract[];
};

const contractSearchParam = 'contractId';

export const PropertyContracts: React.FC<PropertyContractsProps> = ({ contracts }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({
    ...Array(contracts.length).fill(false),
  });
  const handleChange = (panel: number) => (_: SyntheticEvent, isExpanded: boolean) => {
    if (contractId) {
      if (isExpanded) {
        setSearchParams((prev) => ({ ...prev, contract: contracts[panel].id }));
      } else {
        setSearchParams((prev) => ({ ...prev, contract: undefined }));
        setExpanded({ ...expanded, [panel]: isExpanded });
      }
    } else {
      setExpanded({ ...expanded, [panel]: isExpanded });
    }
  };

  const contractId = searchParams.get(contractSearchParam) ?? undefined;
  useEffect(() => {
    const idx = contractId ? contracts.findIndex((c) => c.id === contractId) : 0;
    setExpanded((expanded) => ({
      ...expanded,
      [idx]: true,
    }));
  }, [contracts, contractId]);

  return (
    // TODO: move color to theme
    <Box bgcolor={'#F9FAFB'} width={'100%'}>
      <Box sx={{ maxWidth: '900px' }}>
        {contracts.map((contract, idx) => {
          return (
            <Accordion
              slotProps={{
                transition: {
                  unmountOnExit: true,
                  onEntered: (node) => contractId && node.offsetParent?.scrollIntoView(),
                },
              }}
              expanded={expanded[idx]}
              onChange={handleChange(idx)}
              key={contract.id}
              style={{ marginBottom: 10 }}
              id={`contract-${idx}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ContractDetailsHeader
                  contractName={contract.name}
                  providerName={contract.serviceProvider?.name}
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <ContractDetails contract={contract} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};
