import type {
  BaseLinkedAttachment,
  Invoice,
  InvoiceAttachment,
  Ticket,
  TicketAttachment,
} from '@liftai/asset-management-types';
import html2canvas from 'html2canvas';
import type { PDFPage } from 'pdf-lib';
import { degrees, PageSizes, PDFDocument, rgb } from 'pdf-lib';
import { useCallback } from 'react';

import { StampedInvoiceTemplate } from '~/components/invoices/stampedInvoice/StampedInvoiceTemplate';
import { getApiClient } from '~/utils/api';

export const useStampedInvoice = (containerRef: React.RefObject<HTMLDivElement>) => {
  const triggerDownload = (invoiceNumber: string, blobPart: Uint8Array) => {
    const blob = new Blob([blobPart], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const id = new Date().getTime();
    link.download = `stamped_invoice-${invoiceNumber}-${id}.pdf`;
    link.click();
  };

  const isSupportingData = (a: BaseLinkedAttachment) => a.isPrimary === false;

  const downloadStampedInvoice = useCallback(
    async ({
      invoiceId,
      includeSupportingData = false,
    }: {
      invoiceId: string;
      includeSupportingData?: boolean;
    }) => {
      const apiClient = getApiClient();
      const builder = new StampedInvoiceBuilder(containerRef);

      const invoice: Invoice = await apiClient.invoices.getById(invoiceId);
      const invoiceAttachments = invoice.attachments || [];
      const relatedInvoiceAttachments =
        invoice.relatedInvoices?.flatMap((relatedInvoice) => {
          return relatedInvoice.attachments || [];
        }) || [];
      const invoiceNumber = invoice.number;

      if (!includeSupportingData) {
        builder.withInvoiceAttachments(invoiceAttachments.filter((inv) => !isSupportingData(inv)));
      } else {
        const tickets: Ticket[] = await apiClient.tickets.getByInvoiceId(invoiceId);
        const ticketAttachments = tickets.flatMap((ticket) => ticket.attachments || []);
        builder
          .withInvoiceAttachments(invoiceAttachments)
          .withRelatedInvoicesAttachments(relatedInvoiceAttachments)
          .withTicketsAttachments(ticketAttachments);
      }
      const pdfBlob = await builder.build();

      return triggerDownload(invoiceNumber, pdfBlob);
    },
    [containerRef],
  );

  const StampedInvoiceContainer = useCallback(
    ({ invoice }: { invoice: Invoice }) => (
      <div
        ref={containerRef}
        style={{
          zIndex: -99999,
          position: 'fixed',
          top: '-1000px',
          width: '65%',
        }}
      >
        <StampedInvoiceTemplate invoice={invoice} containerRef={containerRef} />
      </div>
    ),
    [containerRef],
  );

  return {
    downloadStampedInvoice,
    /**
     * The reason behind this approach is that
     * html2canvas library used by the
     * StampedInvoice component requires the
     * component to be present in the DOM in order to
     * generate the PDF.
     */
    StampedInvoiceContainer,
  };
};

export class StampedInvoiceBuilder {
  private invoiceAttachments: InvoiceAttachment[] = [];
  private ticketAttachments: TicketAttachment[] = [];
  private relatedInvoiceAttachments: InvoiceAttachment[] = [];
  private readonly containerRef: React.RefObject<HTMLDivElement>;
  private invoiceAttachmentComparator:
    | ((a: InvoiceAttachment, b: InvoiceAttachment) => number)
    | null = null;
  constructor(containerRef: React.RefObject<HTMLDivElement>) {
    this.containerRef = containerRef;
  }

  setInvoiceAttachmentComparator(
    comparator: (a: InvoiceAttachment, b: InvoiceAttachment) => number,
  ): void {
    this.invoiceAttachmentComparator = comparator;
  }

  getInvoiceAttachmentComparator(): (a: InvoiceAttachment, b: InvoiceAttachment) => number {
    return (
      this.invoiceAttachmentComparator || StampedInvoiceBuilder.defaultInvoiceAttachmentComparator
    );
  }

  withInvoiceAttachments(attachments: InvoiceAttachment[]): StampedInvoiceBuilder {
    this.invoiceAttachments.push(...attachments);
    return this;
  }

  withRelatedInvoicesAttachments(attachments: InvoiceAttachment[]): StampedInvoiceBuilder {
    this.relatedInvoiceAttachments.push(...attachments);
    return this;
  }

  withTicketsAttachments(attachments: TicketAttachment[]): StampedInvoiceBuilder {
    this.ticketAttachments.push(...attachments);
    return this;
  }

  async build(): Promise<Uint8Array> {
    const aDocument = await PDFDocument.create();
    await this.addStampedPage(aDocument);
    await this.rebuildInvoicePDF(aDocument, this.invoiceAttachments);
    await this.rebuildTicketsPDF(aDocument);
    await this.rebuildInvoicePDF(aDocument, this.relatedInvoiceAttachments);
    return aDocument.save();
  }

  private async addStampedPage(aDocument: PDFDocument): Promise<PDFDocument> {
    const canvas = await html2canvas(this.containerRef.current as HTMLDivElement, {
      useCORS: true,
    });
    const imgData = canvas.toDataURL('image/png');
    // Convert the data URL to a Uint8Array and embed it as an image
    const imgDataBytes = await aDocument.embedPng(
      await fetch(imgData).then((res) => res.arrayBuffer()),
    );
    const firstPage = aDocument.addPage(PageSizes.A4);
    const imgProps = firstPage.getSize();

    // Calculate the aspect ratio to fit the image within the PDF page dimensions
    const aspectRatio = canvas.width / canvas.height;
    const maxWidth = imgProps.width;
    const maxHeight = imgProps.height;
    const width = maxWidth;
    const height = Math.min(maxWidth / aspectRatio, maxHeight);

    firstPage.drawImage(imgDataBytes, {
      x: 0,
      y: imgProps.height - height,
      width: width,
      height: height,
    });

    return aDocument;
  }

  private async rebuildInvoicePDF(
    aDocument: PDFDocument,
    invoiceAttachments: InvoiceAttachment[],
  ): Promise<PDFDocument> {
    const existingPdfPagesBuffer = await Promise.all(
      invoiceAttachments.sort(this.getInvoiceAttachmentComparator()).map(async (attachment) => {
        return {
          buffer: await fetch(attachment.attachmentUrl).then((res: Response) => res.arrayBuffer()),
          page: attachment.pageNumber,
          isVoid: attachment.isVoid,
        };
      }),
    );

    const existingPdfPages = await Promise.all(
      existingPdfPagesBuffer.map(async (attachment) => ({
        doc: await PDFDocument.load(attachment.buffer),
        page: attachment.page,
        isVoid: attachment.isVoid,
      })),
    );

    for (const page of existingPdfPages) {
      let [newPage] = await aDocument.copyPages(page.doc, [(page.page ?? 0) - 1]);
      if (page.isVoid) {
        newPage = this.voidPage(newPage);
      }

      aDocument.addPage(newPage);
    }

    return aDocument;
  }

  private async rebuildTicketsPDF(aDocument: PDFDocument): Promise<PDFDocument> {
    const existingPdfPagesBuffer = await Promise.all(
      this.ticketAttachments.reverse().map(async (attachment) => ({
        buffer: await fetch(attachment.attachmentUrl).then((res: Response) => res.arrayBuffer()),
        page: attachment.pageNumber,
      })),
    );

    const existingPdfPages = await Promise.all(
      existingPdfPagesBuffer.map(async (attachment) => ({
        doc: await PDFDocument.load(attachment.buffer),
        page: attachment.page,
      })),
    );
    const pdfPages: PDFPage[] = [];
    for (const page of existingPdfPages) {
      pdfPages.push(...(await aDocument.copyPages(page.doc, [(page.page ?? 0) - 1])));
    }

    for (const page of pdfPages) {
      aDocument.addPage(page);
    }

    return aDocument;
  }

  private voidPage(aPage: PDFPage): PDFPage {
    const { width, height } = aPage.getSize();
    aPage.drawText('VOID', {
      x: width / 2 - 100,
      y: height / 2 - 150,
      size: 150,
      color: rgb(1, 0, 0),
      rotate: degrees(45),
      opacity: 0.3,
    });

    return aPage;
  }

  static defaultInvoiceAttachmentComparator(prev: InvoiceAttachment, curr: InvoiceAttachment) {
    if (prev.isPrimary === curr.isPrimary) {
      if (prev.isVoid === curr.isVoid) return -1;
      return !prev.isVoid ? -1 : 1;
    }

    return prev.isPrimary ? -1 : 1;
  }
}
