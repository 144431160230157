import {
  ToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButtonGroupProps as MUIToggleButtonGroupProps,
} from '@mui/material';
import React from 'react';

type ToggleButtonGroupProps = Omit<MUIToggleButtonGroupProps, 'value' | 'onChange'> & {
  activeView: string;
  viewList: { value: string; title: string }[];
  handleChange: (_: React.MouseEvent<HTMLElement>, newValue: string) => void;
};

function ToggleButtonGroup({
  activeView,
  handleChange,
  viewList,
  ...props
}: ToggleButtonGroupProps) {
  return (
    <MUIToggleButtonGroup
      color="primary"
      exclusive
      aria-label="portfolio-view"
      {...props}
      value={activeView}
      onChange={handleChange}
    >
      {viewList.map((view) => (
        <ToggleButton key={JSON.stringify(view)} value={view.value}>
          {view.title}
        </ToggleButton>
      ))}
    </MUIToggleButtonGroup>
  );
}

export default ToggleButtonGroup;
