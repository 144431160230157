import z from 'zod';
import { contractSchema } from './contract';
import { propertySchema } from './property';
export const CarType = {
    Traction: 'Traction',
    Hydro: 'Hydro',
    Basement: 'Basement',
    Buried: 'Buried',
    Dual: 'Dual',
    Handicap: 'Handicap',
    Holeless: 'Holeless',
    Inverted: 'Inverted',
    MachineLess: 'MachineLess',
    OverheadGear: 'OverheadGear',
    OverheadGearless: 'OverheadGearless',
    Rack: 'Rack',
    Escalator: 'Escalator',
    SideMountGeared: 'SideMountGeared',
    SideMountGearless: 'SideMountGearless',
    Other: 'Other',
};
export const CarTypeValues = Object.values(CarType);
export const CarTypeOptions = {
    [CarType.Traction]: 'Traction',
    [CarType.Hydro]: 'Hydro',
    [CarType.Basement]: 'Basement geared traction',
    [CarType.Buried]: 'Buried direct-acting hydraulic',
    [CarType.Dual]: 'Dual-post holeless hydraulic',
    [CarType.Handicap]: 'Handicap lift',
    [CarType.Holeless]: 'Holeless direct-acting hydraulic',
    [CarType.Inverted]: 'Inverted twin-post holeless hydraulic',
    [CarType.MachineLess]: 'Machine-room-less',
    [CarType.OverheadGear]: 'Overhead geared traction',
    [CarType.OverheadGearless]: 'Overhead gearless traction',
    [CarType.Rack]: 'Rack and pinion',
    [CarType.Escalator]: 'Escalator',
    [CarType.SideMountGeared]: 'Sidemount geared traction',
    [CarType.SideMountGearless]: 'Sidemount gearless traction',
    [CarType.Other]: 'Other',
};
export const CarInventoryStatus = {
    WIP: 'WP',
    READY: 'RD',
    SHIPPED: 'SH',
    INSTALLED: 'IN',
    TESTING: 'TE',
    SUSPENDED: 'SU',
    DECOMMISIONED: 'DE',
};
export const CarInventoryStatusValues = Object.values(CarInventoryStatus);
export const CarInventoryStatusOptions = {
    [CarInventoryStatus.WIP]: 'WIP',
    [CarInventoryStatus.READY]: 'Ready',
    [CarInventoryStatus.SHIPPED]: 'Shipped',
    [CarInventoryStatus.INSTALLED]: 'Installed',
    [CarInventoryStatus.TESTING]: 'Testing',
    [CarInventoryStatus.SUSPENDED]: 'Suspended',
    [CarInventoryStatus.DECOMMISIONED]: 'Decommissioned',
};
export const carSchema = z.object({
    id: z.string(),
    name: z.string(),
    tripCount30Day: z.number(),
    lastTripDate: z.string().nullable(),
    elevatorType: z.enum(CarTypeValues).nullable(),
    criticalUnit: z.boolean(),
    installationYear: z.number(),
    modernizationYear: z.number().nullable(),
    numEntrances: z.number().nullable(),
    speedFpm: z.number().nullable(),
    capacityLbs: z.number().nullable(),
    lastSspiTest: z.string().nullable(),
    driveType: z.string().nullable(),
    controllerModel: z.string().nullable(),
    oem: z.string().nullable(),
    serviceProviderInternalId: z.string().nullable(),
    bank: z
        .object({
        id: z.string(),
        name: z.string(),
        property: z.string(),
    })
        .nullable()
        .optional(),
    inventoryStatus: z.enum(CarInventoryStatusValues),
    numLandings: z.number().gt(0).nullable(),
    // We use `z.lazy()` because `carSchema` and `propertySchema` reference each other.
    property: z.lazy(() => propertySchema),
    contract: contractSchema.nullable(),
    ahjId: z.string().nullable(),
});
export const carDisplaySchema = carSchema.pick({
    id: true,
    name: true,
    tripCount30Day: true,
    lastTripDate: true,
});
export const carFormSchema = carSchema.pick({
    id: true,
    name: true,
    property: true,
});
export const carInPropertySchema = carSchema
    .pick({
    id: true,
    name: true,
    ahjId: true,
    criticalUnit: true,
    elevatorType: true,
    installationYear: true,
    modernizationYear: true,
    numLandings: true,
    inventoryStatus: true,
    speedFpm: true,
    capacityLbs: true,
    controllerModel: true,
    driveType: true,
    lastSspiTest: true,
    numEntrances: true,
    oem: true,
    contract: true,
})
    .extend({
    bank: z.string().nullable(),
    serviceProviderGroup: z.string().nullable(),
    callbacks: z.number().nullable(),
    callbackRate: z.number().nullable(),
    callbackMedianTime: z.number().nullable(),
});
