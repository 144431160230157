import {
  ApproveInvoiceForm,
  InvoiceActionType,
  PartialApproveInvoiceForm,
  RejectInvoiceForm,
} from '@liftai/asset-management-types';
import { useState } from 'react';

import {
  ApproveDialogContent,
  PartialApproveDialogContent,
  RejectDialogContent,
} from '~/components/invoices/InvoiceActionsDialogsContent';
import useInvoice from '~/data/hooks/useInvoice';

/**
 * useFinalDecision hook to handle the final decision of an invoice
 * @param invoiceId
 * @param onClose
 * @returns
 */
export const useFinalDecision = (invoiceId: string | null, onClose: () => void) => {
  const { invoice, reviewInvoice } = useInvoice(invoiceId);

  const [isSaving, setIsSaving] = useState(false);

  if (!invoice) {
    return null;
  }

  if (invoice.status === 'approved') {
    return (
      <ApproveDialogContent
        isSaving={isSaving}
        invoice={invoice}
        onClose={onClose}
        onSubmit={async (formData: ApproveInvoiceForm) => {
          setIsSaving(true);
          await reviewInvoice(InvoiceActionType.APPROVE, formData);
          setIsSaving(false);
          onClose();
        }}
      />
    );
  }

  if (invoice.status === 'rejected') {
    return (
      <RejectDialogContent
        isSaving={isSaving}
        invoice={invoice}
        onClose={onClose}
        onSubmit={async (formData: RejectInvoiceForm) => {
          setIsSaving(true);
          await reviewInvoice(InvoiceActionType.REJECT, formData);
          setIsSaving(false);
          onClose();
        }}
      />
    );
  }

  if (invoice.status === 'partial-approval') {
    return (
      <PartialApproveDialogContent
        isSaving={isSaving}
        invoice={invoice}
        onClose={onClose}
        onSubmit={async (formData: PartialApproveInvoiceForm) => {
          setIsSaving(true);
          await reviewInvoice(InvoiceActionType.PARTIAL, formData);
          setIsSaving(false);
          onClose();
        }}
      />
    );
  }
};
