import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

export type HourlyRate = {
  name: string;
  base: number;
  plus50: number;
  plus70: number;
  plus100: number;
};

export type HourlyRateTableProps = {
  rates: HourlyRate[];
};
export const HourlyRateTable: React.FC<HourlyRateTableProps> = ({ rates }) => {
  return (
    <>
      <Typography variant="h6">Hourly Rates</Typography>
      <TableContainer style={{ width: '500px', marginTop: '24px !important' }}>
        <Table aria-label="Hourly Rates">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell align="left">1.0</TableCell>
              <TableCell align="left">1.5</TableCell>
              <TableCell align="left">1.7</TableCell>
              <TableCell align="left">2.0</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">{row.name}</Typography>
                </TableCell>
                <TableCell align="left">{row.base ? row.base : '-'}</TableCell>
                <TableCell align="left">{row.plus50 ? row.plus50.toFixed(2) : '-'}</TableCell>
                <TableCell align="left">{row.plus70 ? row.plus70.toFixed(2) : '-'}</TableCell>
                <TableCell align="left">{row.plus100 ? row.plus100.toFixed(2) : '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
