import { Link, type LinkProps } from '@mui/material';

export interface LiftAITextLinkProps extends Omit<LinkProps, 'sx'> {
  label: string;
}

/**
 * MUI wrapped Link with standard styling to use
 * throughout the app for consistency.
 * All props available to link except for `sx` and `variant`
 * rest is all the other props that are passed and available in LinkProps
 */
export const LiftAITextLink = ({ label, variant = 'button', ...rest }: LiftAITextLinkProps) => {
  return (
    <Link
      sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
      variant={variant}
      {...rest}
    >
      {label}
    </Link>
  );
};
