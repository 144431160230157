import type { API_UserGroupDetailResponse, User } from '@liftai/asset-management-types';
import { createContext } from 'react';

export type UserData = {
  user: { email?: string; uid: string };
  userGroup: API_UserGroupDetailResponse;
  currencyCode: 'GBP' | 'USD';
  userDetails: User;
};

export const UserContext = createContext<UserData>({
  user: { email: '', uid: '' },
  userGroup: {
    id: '',
    name: '',
    file: '',
  },
  currencyCode: 'USD',
  userDetails: {
    id: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    role: null,
    isLiftaiAdmin: false,
  },
});
