import {
  Contract,
  ContractCustomDetails,
  ContractSummaryDetails,
} from '@liftai/asset-management-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormHelperText,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  mapContractToFormValues,
  useContractDetailsForm,
} from '~/components/contracts/ContractDetailsForm';
import DialogTitleWithCloseButton from '~/components/DialogTitleWithCloseButton';
import usePropertyContracts from '~/data/hooks/usePropertyContracts';

interface EditContractDetailsDialogProps extends DialogProps {
  /**
   * Contract details to edit
   */
  defaultValues: Contract & { customDetails?: ContractCustomDetails };
  /**
   * Callback to close the dialog
   */
  onClose: () => void;
}

export const EditContractDetailsDialog: React.FC<EditContractDetailsDialogProps> = ({
  open,
  onClose,
  defaultValues,
}) => {
  const theme = useTheme();
  const { Fields, Form, hookForm } = useContractDetailsForm();
  const { saveSummaryDetails, saveCustomDetails } = usePropertyContracts();

  const initialData = useMemo(() => mapContractToFormValues(defaultValues), [defaultValues]);

  const onSubmit = async ({ customDetails, ...contractData }: ContractSummaryDetails) => {
    try {
      await Promise.all([
        saveSummaryDetails(defaultValues.id, contractData),
        customDetails ? saveCustomDetails(defaultValues.id, customDetails) : Promise.resolve(),
      ]);

      onClose();
    } catch (error) {
      console.error(error);
      hookForm.setError('root.serverError', {
        type: '400',
        message: 'Failed to save contract details',
      });
    }
  };

  return (
    <FormProvider {...hookForm}>
      <Dialog open={open} onClose={onClose} maxWidth="md" aria-modal>
        <DialogTitleWithCloseButton onClose={onClose} title="Edit Contract Summary" />
        <Form onSubmit={onSubmit} initialData={initialData}>
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'scroll', height: '100%' }}
          >
            <Fields />
            {hookForm.formState.errors.root?.serverError ? (
              <FormHelperText sx={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
                {hookForm.formState.errors.root?.serverError.message}
              </FormHelperText>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained">
              save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </FormProvider>
  );
};
