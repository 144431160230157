import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, ClickAwayListener, Fade, ListItem, MenuList, Paper, Popper } from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridPreferencePanelsValue,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { MouseEvent, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export type UseGridColumns = {
  storeKey: string;
  initialVisibility?: GridColumnVisibilityModel;
};
export const useGridColumns = ({ storeKey, initialVisibility }: UseGridColumns) => {
  const [columnVisibilityModel, storeColumnVisibilityModel] = useLocalStorage<
    GridColumnVisibilityModel | undefined
  >(storeKey, initialVisibility);

  /**
   * Custom GridToolbarColumnsButton that remembers the columns visibility across app reloading
   * and navigation
   */
  const GridToolbarColumnsButton = () => {
    const apiRef = useGridApiContext();
    const [isMenuOpened, setMenuOpened] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopperAnchorClick = (event: MouseEvent) => {
      if (isMenuOpened) {
        setMenuOpened(false);
        setMenuAnchorEl(null);
        return;
      } else {
        setMenuOpened(true);
        setMenuAnchorEl(event.currentTarget as HTMLElement);
      }
      event.stopPropagation();
    };

    const handleClosePopper = () => {
      setMenuOpened(false);
    };

    const canBeMenuOpened = isMenuOpened && Boolean(menuAnchorEl);
    const popperId = canBeMenuOpened ? 'transition-popper' : undefined;

    const handleSaveState = (event: MouseEvent) => {
      event.stopPropagation();
      const { columns } = apiRef.current.exportState();
      storeColumnVisibilityModel(columns?.columnVisibilityModel);
      setMenuOpened(false);
    };

    const handleClearState = (event: MouseEvent) => {
      event.stopPropagation();
      storeColumnVisibilityModel(undefined);
      apiRef.current.setColumnVisibilityModel(initialVisibility ?? {});
      setMenuOpened(false);
    };
    const [preferencePanelVisibility, setPreferencePanelVisibility] = useState(false);

    return (
      <>
        <Button
          aria-describedby={popperId}
          type="button"
          size="small"
          id="custom-view-button"
          onClick={handlePopperAnchorClick}
          startIcon={<ViewColumnIcon />}
        >
          columns
        </Button>
        <ClickAwayListener onClickAway={handleClosePopper}>
          <Popper
            id={popperId}
            open={isMenuOpened}
            anchorEl={menuAnchorEl}
            role={undefined}
            transition
            placement="bottom-start"
            sx={{ zIndex: 'modal', '& .MuiListItem-root': { p: 1 }, '& .MuiButton-root': { p: 0 } }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <MenuList
                    autoFocusItem={isMenuOpened}
                    id="custom-view-menu"
                    aria-labelledby="custom-view-button"
                  >
                    <ListItem>
                      <Button
                        size="small"
                        startIcon={<VisibilityIcon />}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (preferencePanelVisibility) {
                            apiRef.current.hidePreferences();
                            setPreferencePanelVisibility(false);
                          } else {
                            apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
                            setPreferencePanelVisibility(true);
                          }
                        }}
                      >
                        Visibility
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveState}
                        variant="text"
                      >
                        Save Visibility
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        size="small"
                        startIcon={<RestoreIcon />}
                        onClick={handleClearState}
                        variant="text"
                      >
                        Restore Default
                      </Button>
                    </ListItem>
                  </MenuList>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      </>
    );
  };

  return {
    GridToolbarColumnsButton,
    columnVisibilityModel,
  };
};
