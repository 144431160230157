export enum AvailableKpis {
  maintenanceCompletion = 'maintenanceCompletion',
  maintenanceVisit = 'maintenanceVisit',
  maintenanceHours = 'maintenanceHours',
  uptime = 'uptime',
  emergencyCalls = 'emergencyCalls',
  emergencyResponse = 'emergencyResponse',
  callbacks = 'callbacks',
  callbackMedianTime = 'callbackMedianTime',
}

export type AvailableKpisType = keyof typeof AvailableKpis;

export const AvailableKpisArray = Object.values(AvailableKpis) as AvailableKpisType[];

const getKpiFilter = (allowedKPIs: AvailableKpisType[]) => {
  return (kpiToTest: AvailableKpisType) => allowedKPIs.includes(kpiToTest);
};

export default getKpiFilter;
