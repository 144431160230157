import { type AvailableKpisType } from '~/lib/portfolio/getKpiFilter';

const columnWidth = {
  maintenanceCompletion: 1,
  maintenanceVisit: 1,
  maintenanceHours: 1,
  uptime: 1,
  emergencyCalls: 2,
  emergencyResponse: 1,
  callbacks: 2,
  callbackMedianTime: 1,
};

export const smallKpisLayoutWidth = {
  kpis: 4,
  chart: 8,
  maintenanceCompletion: 6,
  maintenanceVisit: 6,
  maintenanceHours: 6,
  uptime: 6,
  emergencyCalls: 12,
  emergencyResponse: 6,
  callbacks: 12,
  callbackMedianTime: 6,
};

export const bigKpsLayoutWidth = {
  kpis: 6,
  chart: 6,
  maintenanceCompletion: 4,
  maintenanceVisit: 4,
  maintenanceHours: 4,
  uptime: 4,
  emergencyCalls: 8,
  emergencyResponse: 4,
  callbacks: 8,
  callbackMedianTime: 4,
};

const getKpisColumnWidth = (kpisToShow: AvailableKpisType[]) => {
  const amountOfColumns = kpisToShow.reduce((acc, kpi) => acc + columnWidth[kpi], 0);

  if (amountOfColumns <= 7) {
    return smallKpisLayoutWidth;
  }

  return bigKpsLayoutWidth;
};

export default getKpisColumnWidth;
