import { EntryTypeKey, InvoiceTypeKey } from '@liftai/asset-management-types';

import { selectedTabSearchParam } from '~/components/global/constants';
import { PortfolioTab } from '~/components/portfolio/PortfolioTabContainer';

/**
 * Converts a URL object to a string suitable for use in href attributes.
 * Strips the origin from the URL, returning only the pathname and search params.
 */
export const urlToHref = (url: URL): string => {
  return url.pathname + url.search;
};

type TicketUrlParams = {
  startDate: string;
  endDate: string;
  carId?: string;
  entryType?: EntryTypeKey[];
  portfolios?: string[];
  number?: string;
  selectedInvoiceId?: string;
  ticketId?: string;
};

/**
 * Builds URLSearchParams for ticket URLs with the given parameters
 */
const buildTicketSearchParams = ({
  startDate,
  endDate,
  carId,
  entryType,
  portfolios,
  number,
  selectedInvoiceId,
  ticketId,
}: TicketUrlParams): URLSearchParams => {
  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  if (carId) {
    params.append('car_id', carId);
  }

  if (number) {
    params.append('number', number);
  }

  if (entryType) {
    for (const type of entryType) {
      params.append('entry_type', type);
    }
  }

  if (selectedInvoiceId) {
    params.append('selectedInvoiceId', selectedInvoiceId);
  }

  if (portfolios) {
    for (const portfolio of portfolios) {
      params.append('portfolio', portfolio);
    }
  }

  if (ticketId) {
    params.append('ticketId', ticketId);
  }

  return params;
};

export const buildUrlForTickets = (params: TicketUrlParams): URL => {
  const searchParams = buildTicketSearchParams(params);
  return new URL(`/tickets?${searchParams}`, window.location.origin);
};

type InvoiceUrlParams = {
  startDate: string;
  endDate: string;
  type?: InvoiceTypeKey[];
  portfolios?: string[];
  kind?: string;
  status?: string[];
  invoiceId?: string;
};

/**
 * Builds URLSearchParams for invoice URLs with the given parameters
 */
const buildSpendingSearchParams = ({
  startDate,
  endDate,
  type,
  portfolios,
  kind,
  status,
  invoiceId,
}: InvoiceUrlParams): URLSearchParams => {
  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  if (type) {
    for (const t of type) {
      params.append('invoice_type', t);
    }
  }

  if (kind) {
    params.append('invoice_kind', kind);
  }

  if (status) {
    for (const s of status) {
      params.append('invoice_status', s);
    }
  }

  if (portfolios) {
    for (const portfolio of portfolios) {
      params.append('portfolio', portfolio);
    }
  }

  if (invoiceId) {
    params.append('selectedInvoiceId', invoiceId);
  }

  return params;
};

export const buildUrlForInvoices = (params: InvoiceUrlParams): URL => {
  const searchParams = buildSpendingSearchParams(params);
  return new URL(`/spending?${searchParams}`, window.location.origin);
};

type PropertyTicketUrlParams = TicketUrlParams & {
  propertyId: string;
};

export const buildUrlForPropertyTickets = (params: PropertyTicketUrlParams) => {
  const searchParams = buildTicketSearchParams(params);
  searchParams.set(selectedTabSearchParam, PortfolioTab.Tickets);
  searchParams.delete('property');

  return new URL(
    `/portfolio/${params.propertyId}?${searchParams.toString()}`,
    window.location.origin,
  );
};

type PropertyInvoiceUrlParams = InvoiceUrlParams & {
  property: string;
};

export const buildUrlForPropertySpending = (params: PropertyInvoiceUrlParams) => {
  const searchParams = buildSpendingSearchParams(params);
  searchParams.set(selectedTabSearchParam, PortfolioTab.Spending);
  searchParams.delete('property');
  return new URL(
    `/portfolio/${params.property}?${searchParams.toString()}`,
    window.location.origin,
  );
};
