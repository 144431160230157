import type { Invoice, Ticket } from '@liftai/asset-management-types';
import { Box, Stack } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';

import { UserContext } from '~/auth/userContext';
import { Notes } from '~/components/common/Notes';
import type { TabDefinition } from '~/components/common/tabs';
import { TabPanel, useTabs } from '~/components/common/tabs';
import Attachments from '~/components/invoices/Attachments';
import InvoiceOverview from '~/components/invoices/InvoiceOverview';
import useInvoice from '~/data/hooks/useInvoice';

enum Tab {
  Overview = 'overview',
  Notes = 'notes',
  Attachments = 'attachments',
}

const tabLabels = [Tab.Overview, Tab.Notes, Tab.Attachments];

type InvoiceDetailTabsPros = {
  defaultSelected?: string;
  overrideLabels?: {
    [key: string]: string;
  };
};

const useInvoiceDetailTabs = ({
  defaultSelected = 'overview',
  overrideLabels,
}: InvoiceDetailTabsPros) => {
  const { tabStrip, tabs, selectedTab } = useTabs(tabLabels, defaultSelected, {
    overrideLabels,
  });

  const buildTabPanel = useCallback(
    (tab: TabDefinition) => {
      const component = (props: React.PropsWithChildren) => (
        <TabPanel tab={tab} hidden={selectedTab !== tab.tabId} {...props} />
      );
      component.displayName = `TabPanel(${tab.label})`;
      return component;
    },
    [selectedTab],
  );

  const tabsComponents = useMemo(
    () => ({
      OverviewTab: buildTabPanel(tabs[Tab.Overview]),
      NotesTab: buildTabPanel(tabs[Tab.Notes]),
      AttachmentsTab: buildTabPanel(tabs[Tab.Attachments]),
    }),
    [buildTabPanel, tabs],
  );

  return {
    tabStrip,
    tabs: tabsComponents,
  };
};

interface InvoiceTabContainerProps {
  invoice?: Invoice;
  tickets: Ticket[] | undefined;
}

export default function InvoiceTabContainer({ invoice, tickets }: InvoiceTabContainerProps) {
  const {
    userDetails: { id: userId },
  } = useContext(UserContext);

  const {
    addNoteToInvoice,
    invoiceNotes: { notes },
  } = useInvoice(invoice?.id ?? null);

  const {
    tabStrip,
    tabs: { OverviewTab, AttachmentsTab, NotesTab },
  } = useInvoiceDetailTabs({
    defaultSelected: 'overview',
    overrideLabels: { Notes: `${notes.length || ''} Notes` },
  });

  return (
    <Stack flex={1}>
      {tabStrip}
      <Box flex={1} sx={{ display: 'flex' }}>
        <OverviewTab>
          <InvoiceOverview invoice={invoice} tickets={tickets} />
        </OverviewTab>
        <NotesTab>
          <Notes notes={notes} onAddNote={(note) => addNoteToInvoice(userId, note)} />
        </NotesTab>
        <AttachmentsTab>
          <Attachments invoice={invoice} tickets={tickets} />
        </AttachmentsTab>
      </Box>
    </Stack>
  );
}
