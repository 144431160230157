import { FirebaseError } from 'firebase/app';
import { z } from 'zod';

/**
 * Returns a new `URLSearchParams` with a `to` param added to it. If a base
 * `URLSearchParams` is provided in the `searchParams` argument, the returned
 * `URLSearchParams` instance will include its original values as well as the new
 * `to` param.
 *
 * @param to The value of the `to` param. Defaults to `/`.
 * @param searchParams The search params to add the `to` param to. If not provided a
 * new `URLSearchParams` will be created.
 * @returns A new `URLSearchParams` instance with a `to` param added.
 */
export function withLoginRedirectSearchParam(to = '/', searchParams?: URLSearchParams) {
  const params = searchParams ?? new URLSearchParams();
  params.set('to', to);

  return params;
}

export const IdentityEmailSchema = z.string({ required_error: 'The email is required' }).email();
export const IdentityEmailFormSchema = z.object({
  email: IdentityEmailSchema,
});
export const IdentityPasswordSchema = z
  .string({ required_error: 'The password is required' })
  .min(8, { message: 'The password must be at least 8 characters long' })
  .max(20, { message: 'The password must be at most 20 characters long' })
  .refine((password) => /[0-9]/.test(password), {
    message: 'The password must contain at least one number',
  })
  .refine((password) => /[!@#$%^&*]/.test(password), {
    message: 'The password must contain at least one special character',
  });

export const IdentityFormSchema = z
  .object({
    email: IdentityEmailSchema,
    password: IdentityPasswordSchema,
    passwordConfirm: z.string({ required_error: 'The password confirmation is required' }),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'The passwords do not match',
    path: ['passwordConfirm'],
  });
export type IdentityForm = z.infer<typeof IdentityFormSchema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ERROR_MAP: Record<string, string> = {
  'auth/invalid-email': 'The email you entered is invalid. Please try again.',
  'auth/wrong-password': 'The password you entered is incorrect. Please try again.',
  'auth/user-not-found': "The email you entered doesn't match any account. Please try again.",
  'auth/expired-action-code':
    'The code you entered is invalid or has expired. Please request a new one.',
  'auth/invalid-action-code':
    'The code you entered is invalid or has expired. Please request a new one.',
  'auth/user-disabled':
    'We are unable to verify your account. If you believe there is an error, please contact support@liftai.com for further assistance',
};

export const getMessageFromError = (
  error: unknown,
  defaultMessage: string = 'An unknown error occurred',
) => {
  if (error instanceof FirebaseError) {
    return ERROR_MAP[error.code] ?? error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return defaultMessage;
};
