import type { TicketWithPortfolio } from '@liftai/asset-management-types';
import { callbackToLabelMap, entryTypeOptions } from '@liftai/asset-management-types';
import { format } from 'date-fns';

import CommaSeparatedList from '~/components/common/CommaSeparatedList';
import { colDefTemplate } from '~/components/table/colDefTemplate';
import {
  onlyContainsFilterOperator,
  onlyIsAnyOfFilterOperator,
  onOrBeforeOrOnOrAfterFilterOperator,
} from '~/components/table/filterOperators';
import { getApplyQuickFilterFn } from '~/components/table/helpers';
import { yesOrNo } from '~/components/utils/booleanToText';
import { LiftAITextLink } from '~/components/utils/helpers';

type GetTicketsTableColumns = {
  /** Add a link to property name that navigates to the property details page */
  linkOnProperty?: boolean;

  /** User can edit tickets */
  canEdit?: boolean;

  /** Callback to edit a ticket */
  getActionsEl: (ticket: TicketWithPortfolio) => JSX.Element[];
};

export function getTicketsTableColumns({
  linkOnProperty = false,
  canEdit,
  getActionsEl,
}: GetTicketsTableColumns) {
  const ticketListCols = colDefTemplate<TicketWithPortfolio>({
    options: {
      autoWidth: true,
    },
    cols: [
      {
        field: 'id',
        headerName: 'ID (internal use only)',
        filterable: false,
      },
      {
        field: 'number',
        headerName: 'Ticket #',
      },
      {
        field: 'entryType',
        headerName: 'Entry Type',
        type: 'singleSelect',
        minWidth: 200,
        filterOperators: onlyIsAnyOfFilterOperator,
        valueFormatter: ({ value }: { value: TicketWithPortfolio['entryType'] }) =>
          callbackToLabelMap.get(value),
        valueGetter: ({ value }: { value: TicketWithPortfolio['entryType'] }) =>
          callbackToLabelMap.get(value),
        valueOptions: entryTypeOptions.map(([key, value]) => ({
          value: key,
          label: value,
        })),
      },
      {
        field: 'startedTime',
        headerName: 'Arrival Time',
        filterOperators: onOrBeforeOrOnOrAfterFilterOperator,
        valueGetter: ({ row }: { row: TicketWithPortfolio }) => new Date(row.startedTime),
        valueFormatter: ({ value }: { value: Date }) => format(value, 'MM/dd/yyyy HH:mm:ss'),
        type: 'date',
        filterable: false,
      },
      {
        field: 'property',
        headerName: 'Property',
        nested: { query: 'property.name' },
        filterOperators: onlyContainsFilterOperator,
        valueFormatter: ({ value }: { value: TicketWithPortfolio['property'] }) =>
          value?.name ?? '-',
        renderCell: ({ value }) => {
          const { searchParams } = new URL(window.location.href);
          return linkOnProperty ? (
            <LiftAITextLink
              label={value?.name}
              target="_blank"
              href={`/portfolio/${value?.id}?${searchParams}`}
            />
          ) : (
            value?.name
          );
        },
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'property.name');
        },
      },
      {
        field: 'portfolio',
        headerName: 'Portfolio',
        nested: { query: 'portfolio.name' },
        filterOperators: onlyContainsFilterOperator,
        valueFormatter: ({ value }: { value: TicketWithPortfolio['property']['portfolio'] }) =>
          value?.name,
        renderCell: ({ value }) => value?.name,
        filterable: false,
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'portfolio.name');
        },
      },
      {
        field: 'carsServiced',
        headerName: 'Cars',
        sortable: false,
        filterable: false,
        valueFormatter: ({ value }) => value.map((car: { name: string }) => car.name).join(', '),
        valueGetter: ({ row }) => {
          const cars = [...row.carsServiced];
          cars.toString = () => row.carsServiced.map((car) => car.name).join(', ');
          return cars;
        },
        renderCell: ({ value = [] }) => {
          const items = value.map((item: { name: string }, i: number) => (
            <span key={i}>{item.name}</span>
          ));
          return <CommaSeparatedList items={items} />;
        },
      },
      {
        field: 'invoice',
        headerName: 'Invoice #',
        filterable: false,
        nested: { query: 'invoice.number' },
        valueFormatter: ({ value }) => value?.number ?? '-',
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.number),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'invoice.number');
        },
      },
      {
        field: 'serviceProviderGroup',
        headerName: 'Provider',
        nested: { query: 'serviceProviderGroup.name' },
        filterable: false,
        filterOperators: onlyContainsFilterOperator,
        valueFormatter: ({ value }) => value?.name ?? '-',
        sortComparator: (prev, curr, ...rest) => prev.name.localeCompare(curr.name),
        getApplyQuickFilterFn(value, colDef, apiRef) {
          return getApplyQuickFilterFn(value, 'serviceProvider.name');
        },
      },
      {
        field: 'problemDescription',
        sortable: false,
        filterable: false,
        headerName: 'Problem Description',
      },
      {
        field: 'workPerformed',
        sortable: false,
        filterable: false,
        valueGetter: ({ row }: { row: TicketWithPortfolio }) => {
          return row.workPerformed;
        },
        headerName: 'Work Performed',
      },
      {
        field: 'equipmentFailure',
        sortable: false,
        filterable: false,
        valueGetter: ({ row }: { row: TicketWithPortfolio }) => yesOrNo(row.equipmentFailure),
        headerName: 'Equipment Related Failure',
      },
      {
        field: 'problemType',
        sortable: false,
        filterable: false,
        valueGetter: ({ row }: { row: TicketWithPortfolio }) => {
          return row.problemType;
        },
        headerName: 'Problem Type',
      },
      {
        field: 'callTime',
        headerName: 'Call Time',
        type: 'dateTime',
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => {
          if ('callTime' in row && row.callTime) {
            return new Date(row.callTime);
          }
          return null;
        },
        valueFormatter: ({ value }: { value: Date | null }) =>
          value ? format(value, 'MM/dd/yyyy HH:mm:ss') : '-',
      },
      {
        field: 'finishedTime',
        headerName: 'Departure Time',
        type: 'dateTime',
        filterable: false,
        sortable: false,
        valueGetter: ({ row }) => new Date(row.finishedTime),
        valueFormatter: ({ value }: { value: Date }) => format(value, 'MM/dd/yyyy HH:mm:ss'),
      },
      {
        field: 'timeOnSiteRegularHours',
        headerName: 'Time on Site',
        filterable: false,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'timeOnSiteOvertime',
        filterable: false,
        sortable: false,
        headerName: 'Time on Site (OT)',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'travelTimeRegularHours',
        headerName: 'Travel Time',
        filterable: false,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'travelTimeOvertime',
        headerName: 'Travel Time (OT)',
        filterable: false,
        sortable: false,
        valueFormatter: ({ value }) => value || '-',
      },
    ],
  });

  if (canEdit) {
    ticketListCols.push({
      field: 'actions',
      type: 'actions',
      getActions: ({ row: ticket }) => getActionsEl(ticket),
      // TODO Possibly should use `theme.spacing` but it's not available in this context
      width: 70,
      align: 'right',
    });
  }

  return ticketListCols;
}
