import type { Invoice, InvoiceStatusKey } from '@liftai/asset-management-types';
import {
  InvoiceKind,
  InvoiceStatus,
  invoiceStatusToLabelMap,
} from '@liftai/asset-management-types';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useRef } from 'react';

import { toDateFormatMMddyyyy } from '~/utils/format';

const InnerBox = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(4),
    height: '100%',
  };
});

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: '1px',
  paddingRight: '1px',
  height: 'auto',
  borderStyle: 'solid',
  borderWidth: '2px',
}));

const TitleHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  height: theme.spacing(9),
  width: '100%',
  textAlign: 'center',
  lineHeight: 'unset',
  padding: theme.spacing(2),
  color: 'white',
  fontWeight: '900',
}));

const ProposedAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginRight: '0.5rem',
  color: 'rgba(0, 0, 0, 0.6)',
  paddingBottom: '1rem',
}));

const AmountAfterReview = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginRight: '0.5rem',
  color: '#000000',
  paddingBottom: theme.spacing(2),
}));

const DateOfDecision = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
}));

const Logo = styled('img')(({ theme }) => ({
  width: '60%',
  height: 'auto',
  float: 'right',
}));

const InvoiceNumberBox = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  textAlign: 'left',
  padding: theme.spacing(2),
  paddingBottom: 0,
  color: 'rgba(0, 0, 0, 0.87)',
  fontWeight: '500',
}));

export const StampedInvoiceTemplate = ({
  invoice,
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  invoice: Invoice;
}) => {
  const theme = useTheme();
  const bgColorMap: Map<InvoiceStatusKey, string> = new Map([
    [InvoiceStatus.Approved, theme.palette.success.main],
    [InvoiceStatus.PartialApproval, theme.palette.warning.main],
    [InvoiceStatus.Rejected, theme.palette.error.main],
  ]);

  const ref = useRef<HTMLDivElement>(containerRef?.current);

  const invoiceStatusOption = invoiceStatusToLabelMap.get(invoice.status);

  return (
    <Box ref={ref} height="auto">
      <InnerBox>
        <TitleHeader
          sx={{
            background: bgColorMap.get(invoice.status),
          }}
          variant="h2"
        >
          {invoice.kind == InvoiceKind.Invoice ? 'Invoice' : 'Proposal'} {invoiceStatusOption}
        </TitleHeader>

        <ContentBox borderColor={bgColorMap.get(invoice.status)}>
          <InvoiceNumberBox>Document #: {invoice.number}</InvoiceNumberBox>
          {invoiceStatusOption !== 'Approved' && (
            <>
              <div style={{ padding: theme.spacing(2) }}>
                <ProposedAmount variant="h6" fontWeight="bold">
                  Amount: ${invoice.proposedAmount}
                </ProposedAmount>
                {invoice.countTowardsSavings || invoiceStatusOption === 'Partial Approval' ? (
                  <AmountAfterReview variant="h6" fontWeight="bold">
                    Amount after review: $
                    {invoiceStatusOption === 'Rejected' ? 0 : invoice.reviewedAmount}
                  </AmountAfterReview>
                ) : null}
              </div>
            </>
          )}
          {invoice.addStampedReason ? (
            <div
              style={{
                fontWeight: 'lighter',
                fontSize: '1.1rem',
                marginRight: '0.5rem',
                color: '#000000',
                padding: theme.spacing(2),
              }}
            >
              <strong
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Reason:
              </strong>
              <p
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  maxWidth: '100%',
                }}
              >
                {invoice.reason}
              </p>
            </div>
          ) : null}
        </ContentBox>
      </InnerBox>
      <Grid
        container
        sx={{
          padding: '0rem 2rem',
        }}
      >
        <Grid item xs={6}>
          <DateOfDecision>
            Date of decision:{' '}
            {toDateFormatMMddyyyy(invoice.dateStamped ?? new Date().toISOString())} Consultant:{' '}
            {invoice.consultant?.fullName}
          </DateOfDecision>
        </Grid>
        <Grid item xs={6}>
          {invoice.stampFile ? (
            <Logo
              src={`${invoice.stampFile}?time=${new Date().getTime()}`}
              alt="Consultant Logo"
              crossOrigin="anonymous"
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
