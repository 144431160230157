import type {
  API_TicketFilterSearchParams,
  EntryTypeKey,
  Ticket,
  TicketWithPortfolio,
} from '@liftai/asset-management-types';
import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';

import { getApiClient } from '~/utils/api';

const ticketSearchParam = 'ticketId';
const selectedPeriodRangeAfterId = 'selected-period-range-after';
const selectedPeriodRangeBeforeId = 'selected-period-range-before';

export const getFilterModelFromQueryParams = (searchParams: URLSearchParams): GridFilterModel => {
  const ticketId = searchParams.get(ticketSearchParam);
  const entryType = searchParams.getAll('entry_type') as EntryTypeKey[];
  const startDate = searchParams.get('start_date');
  const endDate = searchParams.get('end_date');
  const property = searchParams.get('property');
  const carId = searchParams.get('car_id');
  const portfolios = searchParams.getAll('portfolio');
  const number = searchParams.get('number');
  const items: GridFilterModel['items'] = [];

  if (ticketId) {
    items.push({
      id: 'id',
      field: 'id',
      value: ticketId,
      operator: 'equals',
    });
  }

  if (entryType.length > 0) {
    items.push({
      id: 'entryType',
      field: 'entryType',
      value: entryType,
      operator: 'isAnyOf',
    });
  }

  if (startDate) {
    items.push({
      id: selectedPeriodRangeAfterId,
      field: 'startedTime',
      // This comes from portfolio which uses yyyy-MM vs yyyy-MM-dd so we need to add the day
      value: format(new Date(startDate), 'yyyy-MM-dd'),
      operator: 'onOrAfter',
    });
  }

  if (endDate) {
    items.push({
      id: selectedPeriodRangeBeforeId,
      field: 'startedTime',
      // This comes from portfolio which uses yyyy-MM vs yyyy-MM-dd so we need to add the day
      value: format(new Date(endDate), 'yyyy-MM-dd'),
      operator: 'onOrBefore',
    });
  }

  if (property) {
    // This will override the stored filter model
    // it's sort of a hack. The other way to do it would be a customizer
    // to mergeFilterModel
    items.push({
      id: 'property',
      field: 'property',
      operator: 'equals',
    });
  }

  if (carId) {
    items.push({
      id: 'carId',
      field: 'carId',
      operator: 'equals',
      value: carId,
    });
  }

  if (portfolios) {
    for (const portfolio of portfolios) {
      items.push({
        id: 'portfolio',
        field: 'portfolio',
        operator: 'contains',
        value: portfolio,
      });
    }
  }

  if (number) {
    items.push({
      id: 'number',
      field: 'number',
      operator: 'contains',
      value: number,
    });
  }

  return { items };
};

export const mapTicketToTicketWithPortfolio = (ticket: Ticket): TicketWithPortfolio => {
  return {
    ...ticket,
    // Flat the portfolio to be used by the table
    portfolio: ticket.property.portfolio,
  } as TicketWithPortfolio;
};

type BuildFilterParamsOpts = {
  propertyId: string | null;
  page: number;
  pageSize: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  searchParams: URLSearchParams;
};

const buildFilterParams = ({
  propertyId,
  page,
  pageSize,
  filterModel,
  sortModel,
  searchParams,
}: BuildFilterParamsOpts): API_TicketFilterSearchParams | null => {
  // NOTE: MUI DataGrid passes odd values sometimes while loading. This is a safety check.
  if (pageSize <= 0 || page < 0) {
    return null;
  }

  return {
    limit: pageSize,
    offset: page * pageSize,
    ordering:
      sortModel.length > 0
        ? sortModel.map((item) => `${item.sort === 'desc' ? '-' : ''}${item.field}`).join(',')
        : '-startedTime',
    search: filterModel.quickFilterValues?.map((value) => value).join(' '),
    property: propertyId ?? undefined,
    carId: searchParams.get('car_id') ?? undefined,
    propertyName: filterModel.items.find((item) => item.field === 'property')?.value,
    number: filterModel.items.find((item) => item.field === 'number')?.value,
    entryType: filterModel.items.find((item) => item.field === 'entryType')?.value,
    portfolios: searchParams.getAll('portfolio'),
    serviceProviders: searchParams.getAll('service_provider'),
    regions: searchParams.getAll('region'),
    startedTimeAfter: searchParams.get('start_date') ?? undefined,
    startedTimeBefore: searchParams.get('end_date') ?? undefined,
  };
};

const ticketsFetcher = async ([_, params]: readonly ['ticket', API_TicketFilterSearchParams]) => {
  const apiClient = getApiClient();
  return await apiClient.tickets.filter(params);
};

export type UseTicketsOptions = {
  propertyId?: string | null;
  carId?: string | null;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
};

export function useTickets({
  propertyId = null,
  paginationModel,
  filterModel,
  sortModel,
}: UseTicketsOptions) {
  const [searchParams] = useSearchParams();

  const filterParams = useMemo(
    () =>
      buildFilterParams({
        propertyId,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        filterModel,
        sortModel,
        searchParams,
      }),
    [propertyId, paginationModel, filterModel, sortModel, searchParams],
  );

  const [filterParamsDebounced] = useDebounceValue(filterParams, 500);

  const onExport = useCallback(async () => {
    const apiClient = getApiClient();
    const { results } = await apiClient.tickets.filter({
      ...filterParams,
      limit: undefined,
      offset: undefined,
    });
    return results.map((result) => mapTicketToTicketWithPortfolio(result));
  }, [filterParams]);

  const { data, isLoading } = useSWR(
    filterParamsDebounced ? (['ticket', filterParamsDebounced] as const) : null,
    ticketsFetcher,
  );

  const tickets = data?.results?.map((result) => mapTicketToTicketWithPortfolio(result)) ?? [];
  const numTickets = data?.metadata.numTickets ?? 0;
  const metadata = data?.metadata ?? null;

  return {
    tickets,
    numTickets,
    metadata,
    isLoading,
    onExport,
  };
}
