import { Box } from '@mui/material';

interface CommaSeparatedListProps {
  items?: React.ReactNode[];
}

const CommaSeparatedList: React.FC<CommaSeparatedListProps> = ({ items = [] }) => {
  return (
    <Box textOverflow="ellipsis" overflow="hidden">
      {items.length !== 0 ? `(${items.length}) ` : '-'}
      {items.map((item, i) => [i > 0 && ', ', item])}
    </Box>
  );
};

export default CommaSeparatedList;
