import {
  type Invoice,
  InvoiceKind,
  type InvoiceWithPortfolio,
  isInvoiceAutoUpload,
  isInvoiceFinal,
} from '@liftai/asset-management-types';
import { Delete, Download, Edit } from '@mui/icons-material';
import type {
  GridEventListener,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-premium';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { UserContext } from '~/auth/userContext';
import { AIIcon } from '~/components/icons';
import { useFinalDecision } from '~/components/invoices/finalDecision/useFinalDecision';
import { InvoiceDetailDrawerFlowContext } from '~/components/invoices/InvoiceDetailDrawerContext';
import InvoiceDialog from '~/components/invoices/InvoiceDialog';
import { useStampedInvoice } from '~/components/invoices/stampedInvoice';
import LiftAITable from '~/components/table';
import { useDeleteEntityDialog } from '~/components/tickets';
import { mapInvoiceToFormValues } from '~/components/uploadDocument/InvoiceForm';
import useInvoice from '~/data/hooks/useInvoice';
import { useGridColumns } from '~/hooks/useGridColumns';
import { useGridExporter } from '~/hooks/useGridExporter';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

import { getSpendingTableColumns } from './spendingTableColumns';

const defaultSortModel: GridSortModel = [{ field: 'date', sort: 'desc' }];

export type SpendingTableProps = {
  invoices: InvoiceWithPortfolio[];
  numInvoices: number;
  isLoading: boolean;
  onExport: () => Promise<InvoiceWithPortfolio[]>;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onFilterModelChange: (model: GridFilterModel) => void;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  hiddenColumns?: string[];
  enableQuickFilter?: boolean;
};

export function SpendingTable({
  invoices,
  numInvoices,
  isLoading,
  onExport,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
  paginationModel,
  filterModel,
  sortModel,
  hiddenColumns = [],
  enableQuickFilter = false,
}: SpendingTableProps) {
  const [gridAction, setGridAction] = useState<{
    actionName: 'actionEdit' | 'actionDelete' | 'actionEditDecision';
    invoiceId: string;
  } | null>(null);
  const clearGridAction = useCallback(() => {
    setGridAction(null);
  }, []);
  const [currentInvoice, setCurrentInvoice] = useState<Invoice | null>(null);
  const { onInvoiceSelected } = useContext(InvoiceDetailDrawerFlowContext);

  const { showEntityActionSnackbar } = useLAISnackbar();
  const apiRef = useGridApiRef();
  const invoiceRef = useRef<HTMLDivElement>(null);
  const { downloadStampedInvoice, StampedInvoiceContainer } = useStampedInvoice(invoiceRef);

  const { currencyCode } = useContext(UserContext);

  const { invoice: invoiceToEdit, deleteInvoice } = useInvoice(
    gridAction?.actionName === 'actionDelete' || gridAction?.actionName === 'actionEdit'
      ? gridAction.invoiceId
      : null,
  );
  const invoiceFormData = useMemo(
    () => (invoiceToEdit ? mapInvoiceToFormValues(invoiceToEdit) : null),
    [invoiceToEdit],
  );
  const finalDecisionForm = useFinalDecision(
    gridAction?.actionName === 'actionEditDecision' ? gridAction.invoiceId : null,
    () => setGridAction(null),
  );

  const { GridToolbarExport } = useGridExporter<InvoiceWithPortfolio>({
    fetcher: onExport,
    apiRef,
    currencyFields: ['proposedAmount', 'reviewedAmount', 'savings'],
    onStartExport() {
      showEntityActionSnackbar(
        {
          name: 'Exporting',
          action: ActionEnum.StatusChange,
          id: 'Invoices',
        },
        {
          variant: 'info',
          persist: true,
        },
      );
    },
    onFinishedExport() {
      showEntityActionSnackbar(
        {
          name: 'Exported',
          action: ActionEnum.StatusChange,
          id: 'Invoices',
        },
        {
          variant: 'success',
          autoHideDuration: 5000,
        },
      );
    },
    onErrorExport() {
      showEntityActionSnackbar(
        {
          name: 'Exporting',
          action: ActionEnum.Fail,
          id: 'Invoices',
        },
        {
          variant: 'error',
          autoHideDuration: 5000,
        },
      );
    },
  });

  const { GridToolbarColumnsButton, columnVisibilityModel } = useGridColumns({
    storeKey: 'invoices-table-columns-visibility',
    initialVisibility: {
      ...Object.fromEntries(hiddenColumns.map((col) => [col, false])),
    },
  });

  const { dialog: deleteDialog, openForEntity: openDeleteDialogForInvoice } =
    useDeleteEntityDialog<Invoice>({
      onDelete: async (invoice) => {
        await deleteInvoice(invoice.id);

        showEntityActionSnackbar(
          {
            name: invoice.kind,
            action: ActionEnum.Delete,
            id: invoice.number,
          },
          {
            variant: 'info',
          },
        );
      },
      entityName: 'Invoice',
    });

  const onRowClick: GridEventListener<'rowClick'> = useCallback(
    ({ row }) => {
      onInvoiceSelected(row.id);
    },
    [onInvoiceSelected],
  );

  const spendingTableCols = useMemo(() => {
    const columnActions = (invoice: Invoice): JSX.Element[] => {
      const actions: JSX.Element[] = [];

      if (isInvoiceAutoUpload(invoice)) {
        actions.push(
          <GridActionsCellItem
            key="processed by AI"
            title="This invoice was processed by AI and may require verification. Please review for accuracy."
            label="processed by AI"
            icon={<AIIcon />}
            disabled
            // pointerEvents allow the tooltip to show on hover
            style={{ padding: 0, pointerEvents: 'all' }}
          />,
        );
      }

      if (!isInvoiceFinal(invoice)) {
        actions.push(
          <GridActionsCellItem
            key="edit"
            icon={<Edit />}
            onClick={() => setGridAction({ actionName: 'actionEdit', invoiceId: invoice.id })}
            label="Edit"
            showInMenu
          />,
        );
      } else {
        actions.push(
          <GridActionsCellItem
            key="edit"
            icon={<Edit />}
            onClick={() =>
              setGridAction({ actionName: 'actionEditDecision', invoiceId: invoice.id })
            }
            label="Edit Stamped Invoice"
            showInMenu
          />,
        );
      }

      actions.push(
        <GridActionsCellItem
          key="delete"
          showInMenu
          icon={<Delete />}
          label="Delete"
          onClick={() => openDeleteDialogForInvoice(invoice)}
        />,
      );

      if (isInvoiceFinal(invoice)) {
        actions.push(
          <GridActionsCellItem
            key="download"
            showInMenu
            icon={<Download />}
            label={
              invoice.kind === InvoiceKind.Proposal
                ? 'Download Stamped Proposal'
                : 'Download Stamped Invoice'
            }
            onClick={async () => await downloadStampedInvoice({ invoiceId: invoice.id })}
          />,
          <GridActionsCellItem
            key="download"
            showInMenu
            icon={<Download />}
            label="Download with Supporting Data"
            onClick={async () =>
              await downloadStampedInvoice({ invoiceId: invoice.id, includeSupportingData: true })
            }
          />,
        );
      }

      return actions;
    };

    const columns = getSpendingTableColumns({
      currencyCode,
      additionalColumns: [
        {
          field: 'actions',
          type: 'actions',
          getActions: ({ row: invoice }) => columnActions(invoice as unknown as Invoice),
          width: 70,
          align: 'right',
        },
      ],
    });

    return columns;
  }, [currencyCode, downloadStampedInvoice, openDeleteDialogForInvoice]);

  const initialState = {
    pinnedColumns: { right: ['actions'] },
    columns: {
      columnVisibilityModel,
    },
    sorting: {
      sortModel: defaultSortModel,
    },
  };

  return (
    <>
      <LiftAITable<InvoiceWithPortfolio>
        rows={invoices}
        onCellClick={({ row }) => setCurrentInvoice(row)}
        apiRef={apiRef}
        rowCount={numInvoices}
        loading={isLoading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        disableRowGrouping
        filterMode="server"
        filterModel={filterModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        CustomGridToolbarExport={GridToolbarExport}
        CustomGridToolbarColumnsButton={GridToolbarColumnsButton}
        onFilterModelChange={onFilterModelChange}
        filterToPreserve={['date', 'portfolio']}
        columns={spendingTableCols}
        tableHeaderTitle={'Spending'}
        initialState={initialState}
        enableQuickFilter={enableQuickFilter}
        onRowClick={onRowClick}
      />
      {invoiceFormData ? (
        <InvoiceDialog
          open
          onClose={clearGridAction}
          onSubmit={clearGridAction}
          isEdit
          kind={invoiceFormData.kind}
          defaultValues={invoiceFormData}
        />
      ) : null}
      {deleteDialog}
      {gridAction?.actionName === 'actionEditDecision' ? finalDecisionForm : null}
      {currentInvoice && isInvoiceFinal(currentInvoice) ? (
        <StampedInvoiceContainer invoice={currentInvoice} />
      ) : null}
    </>
  );
}
