import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import type { TabDefinition } from '~/components/common/tabs/definition';

interface TabPanelProps {
  hidden: boolean;
  tab: TabDefinition;
  children?: React.ReactNode;
}

export default function TabPanel({ hidden, tab: { panelId, tabId }, children }: TabPanelProps) {
  const location = useLocation();

  // Check if current path is Property
  const isPropertyPage = location.pathname.match(/^\/portfolio\/[0-9a-f-]+$/i) !== null;
  return (
    <Box
      role="tabpanel"
      id={panelId}
      display={hidden ? 'none' : 'flex'}
      width="100%"
      aria-labelledby={tabId}
      sx={{ flex: '1 0 auto', ...(isPropertyPage && { padding: '0px 8px 0px 8px' }) }}
    >
      {children}
    </Box>
  );
}
