import { useCallback } from 'react';
import { useMatches, useSearchParams } from 'react-router-dom';

import type { ItemType } from '~/components/common/SelectCheckbox';
import SelectCheckbox from '~/components/common/SelectCheckbox';
import MonthRangePicker, {
  getDefaultMonthRange,
  parseYearMonthDay,
} from '~/components/date/MonthRangePicker/MonthRangePicker';
import type { SelectedDateRange } from '~/components/date/MonthRangePicker/types';
import { useGlobalFilterContext } from '~/contexts/globalFilterContext';
import { toDateLocal } from '~/utils/format';

export type FilterName =
  | 'MonthRangePicker'
  | 'portfolioDropDown'
  | 'serviceProviderDropDown'
  | 'regionDropDown';

export type Filter = {
  name: FilterName;
  component: JSX.Element;
};

type GetMonthRangePickerFilter = {
  startDate: string;
  endDate: string;
  eventHandler: (selectedRage: SelectedDateRange) => void;
};

const getMonthRangePickerFilter = ({
  startDate,
  endDate,
  eventHandler,
}: GetMonthRangePickerFilter): Filter => {
  return {
    name: 'MonthRangePicker',
    component: (
      <MonthRangePicker
        placeHolder="Period"
        onSelect={eventHandler}
        value={[parseYearMonthDay(startDate), parseYearMonthDay(endDate)]}
      />
    ),
  };
};

type GetDropDownFilter = {
  name: FilterName;
  label: string;
  values: ItemType[];
  initialState: string[];
  eventHandler: (selectedItems: string[]) => void;
};

type RemoveFiltersType = {
  [key: string]: string[];
};

// This is the list of filters that should be removed from the sidebar
const routesToFilterForFilterName: RemoveFiltersType = {
  filterPortfolios: ['property-detail', 'tools'],
  filterServiceProviders: ['property-detail', 'tools'],
  filterRegions: ['property-detail', 'tools'],
  filterPeriod: ['tools'],
};

const getDropDownFilter = ({
  name,
  label,
  values,
  eventHandler,
  initialState,
}: GetDropDownFilter): Filter => {
  return {
    name,
    component: (
      <SelectCheckbox
        label={label}
        values={values}
        onSelect={eventHandler}
        initState={initialState}
      />
    ),
  };
};

export type GlobalFilters = {
  filters: Filter[];
  searchParams: URLSearchParams;
};

export type UseGlobalFilters = {
  portfolioFilterPros?: Pick<GetDropDownFilter, 'initialState' | 'values'>;
};

export const useGlobalFilters = (): GlobalFilters => {
  const matches = useMatches();
  const [searchParams, setSearchParams] = useSearchParams();
  const filters: Filter[] = [];
  const { portfolios, serviceProviders, regions } = useGlobalFilterContext();

  const defaultDateRange = getDefaultMonthRange();
  const filterDateStart = searchParams.get('start_date') || defaultDateRange[0];
  const filterDateEnd = searchParams.get('end_date') || defaultDateRange[1];

  //  Range date
  const monthRangePickerFilterProps = {
    startDate: filterDateStart,
    endDate: filterDateEnd,
  };

  const monthRangePickerFilterHandler = useCallback(
    (selectedRange: SelectedDateRange) => {
      setSearchParams((prev) => {
        if (selectedRange[0]) {
          prev.set('start_date', toDateLocal(selectedRange[0]));
        }
        if (selectedRange[1]) {
          prev.set('end_date', toDateLocal(selectedRange[1]));
        }
        return prev;
      });
    },
    [setSearchParams],
  );
  if (showFilter('filterPeriod', matches) && monthRangePickerFilterProps) {
    const monthRangePickerFilter = getMonthRangePickerFilter({
      ...monthRangePickerFilterProps,
      eventHandler: monthRangePickerFilterHandler,
    });
    filters.push(monthRangePickerFilter);
  }

  // Portfolios
  const portfolioFilterHandler = useCallback(
    (selectedItems: string[]) => {
      setSearchParams((prev) => {
        prev.delete('portfolio');

        for (const portfolio of selectedItems) {
          prev.append('portfolio', portfolio);
        }

        return prev;
      });
    },
    [setSearchParams],
  );

  if (showFilter('filterPortfolios', matches)) {
    const portfoliosFromQueryParams = searchParams.getAll('portfolio');
    const filterPortfolios = getFilterItem(portfolios, portfoliosFromQueryParams);
    const portfolioFilterPros = {
      values: portfolios,
      initialState: filterPortfolios.length ? filterPortfolios : [],
    };

    const portfolioFilter = getDropDownFilter({
      name: 'portfolioDropDown',
      label: 'Portfolio',
      ...portfolioFilterPros,
      eventHandler: portfolioFilterHandler,
    });
    filters.push(portfolioFilter);
  }

  // Service Provider
  const serviceProviderFilterHandler = useCallback(
    (selectedItems: string[]) => {
      setSearchParams((prev) => {
        prev.delete('service_provider');

        for (const serviceProvider of selectedItems) {
          prev.append('service_provider', serviceProvider);
        }

        return prev;
      });
    },
    [setSearchParams],
  );

  if (showFilter('filterServiceProviders', matches)) {
    const serviceProvidersFromQueryParams = searchParams.getAll('service_provider');
    const filterServiceProviders = getFilterItem(serviceProviders, serviceProvidersFromQueryParams);
    const serviceProviderFilterPros = {
      values: serviceProviders,
      initialState: filterServiceProviders.length ? filterServiceProviders : [],
    };

    const serviceProviderFilter = getDropDownFilter({
      name: 'serviceProviderDropDown',
      label: 'Service Provider',
      ...serviceProviderFilterPros,
      eventHandler: serviceProviderFilterHandler,
    });
    filters.push(serviceProviderFilter);
  }

  // Regions
  const regionFilterHandler = useCallback(
    (selectedItems: string[]) => {
      setSearchParams((prev) => {
        prev.delete('region');

        for (const region of selectedItems) {
          prev.append('region', region);
        }

        return prev;
      });
    },
    [setSearchParams],
  );

  if (showFilter('filterRegions', matches)) {
    const regionsFromQueryParams = searchParams.getAll('region');
    const filterRegions = getFilterItem(regions, regionsFromQueryParams);
    const regionFilterPros = {
      values: regions,
      initialState: filterRegions.length ? filterRegions : [],
    };

    const regionFilter = getDropDownFilter({
      name: 'regionDropDown',
      label: 'Region',
      ...regionFilterPros,
      eventHandler: regionFilterHandler,
    });
    filters.push(regionFilter);
  }

  return { filters, searchParams };
};

function getFilterItem(items: { id: string }[], queryParams: string[]) {
  return items.map((p) => p.id).filter((id) => queryParams.includes(id));
}

function showFilter(filterName: string, matches: ReturnType<typeof useMatches>) {
  const routesToFilter = routesToFilterForFilterName[filterName];
  const lastRoute = matches[matches.length - 1].id;

  return !routesToFilter.includes(lastRoute);
}
