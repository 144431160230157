import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const AIIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="22" height="22" rx="2" fill="#E7F2FF" />
      <path
        d="M9.62414 19.0002C9.46873 18.8041 9.33891 18.6951 9.27637 18.5549C8.8926 17.6945 8.51262 16.8322 8.15538 15.9613C7.79625 15.0848 7.18979 14.4765 6.31801 14.1079C5.4216 13.7288 4.53466 13.328 3.65246 12.9167C3.50464 12.8476 3.40324 12.6779 3.28101 12.5548C3.39945 12.4448 3.50085 12.2951 3.64014 12.2316C4.54035 11.8223 5.44718 11.4281 6.35402 11.0348C7.15568 10.6871 7.72802 10.1176 8.07389 9.31308C8.45197 8.43372 8.8329 7.55436 9.23089 6.68353C9.30859 6.51391 9.47158 6.38315 9.59476 6.23438C9.73121 6.38883 9.9141 6.52149 9.99654 6.70059C10.3964 7.57047 10.7707 8.45267 11.1507 9.33108C11.4909 10.1176 12.0537 10.6814 12.8383 11.0244C13.7442 11.4205 14.6539 11.809 15.5532 12.2193C15.7048 12.2885 15.8128 12.4543 15.9417 12.5756C15.809 12.6931 15.6953 12.8523 15.5418 12.9224C14.6852 13.3138 13.8229 13.6928 12.9549 14.0576C12.085 14.4243 11.4719 15.0299 11.1052 15.9026C10.7347 16.7857 10.3452 17.6613 9.9539 18.535C9.89041 18.6771 9.77196 18.7946 9.62414 19.0002Z"
        fill="#78A8FA"
      />
      <path
        d="M12.2766 6.71169C12.7305 6.5108 13.173 6.28054 13.6392 6.11471C14.4342 5.83233 14.9639 5.32442 15.2416 4.51898C15.4055 4.04234 15.6519 3.59319 15.921 3C16.0859 3.30607 16.1891 3.47 16.2668 3.64436C17.2599 5.86929 16.8752 5.48836 19.1001 6.48048C19.2764 6.55913 19.4431 6.65957 19.7189 6.8055C19.446 6.95332 19.283 7.05756 19.1068 7.13431C16.8515 8.11411 17.2448 7.79857 16.2716 9.96664C16.1958 10.1344 16.1133 10.2992 15.9854 10.5693C15.848 10.357 15.7438 10.2358 15.6822 10.0955C14.6361 7.71708 15.0729 8.17571 12.7011 7.09641C12.5523 7.02913 12.4121 6.94479 12.2671 6.86804C12.2699 6.81592 12.2737 6.76381 12.2766 6.71264V6.71169Z"
        fill="#78A8FA"
      />
    </svg>
  </SvgIcon>
);

export default AIIcon;
