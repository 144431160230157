import { type UserRole } from '@liftai/asset-management-types';
import { initializeApp } from 'firebase/app';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  type User,
} from 'firebase/auth';
import { json as reactRouterJson, redirect } from 'react-router-dom';

import { withLoginRedirectSearchParam } from '~/auth/utils';
import { LiftAPIClient } from '~/data/LiftAPIClient';
import firebaseConfig from '~/firebase.config';

const { VITE_API_HOST: API_HOST } = import.meta.env;

initializeApp(firebaseConfig);

const getUser = () => {
  return new Promise<User | null>((resolve) => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      unsubscribe();
      resolve(user);
    });
  });
};

const defaultGetIdToken = async () => {
  const user = await getUser();

  if (!user) {
    throw new Error('User is not logged in');
  }

  return user.getIdToken();
};

const _sessionHelper = {
  setUserRole(userRole: UserRole) {
    localStorage.setItem('userRole', userRole ?? '');
  },

  setIsLiftaiAdmin(isLiftaiAdmin: boolean) {
    localStorage.setItem('isLiftaiAdmin', isLiftaiAdmin.toString());
  },

  getUserRole(): UserRole {
    return localStorage.getItem('userRole') as UserRole;
  },

  getIsLiftaiAdmin(): boolean {
    return localStorage.getItem('isLiftaiAdmin') === 'true';
  },
} as const;

const authHelper = (sessionHelper: typeof _sessionHelper, firebaseAuth = getAuth()) => ({
  async signOut() {
    await signOut(firebaseAuth);
  },
  async requestPasswordReset(email: string) {
    await sendPasswordResetEmail(firebaseAuth, email);
  },
  async resetPassword(email: string, password: string, code: string) {
    await confirmPasswordReset(firebaseAuth, code, password);
  },
  async signUpUserWithEmailAndPassword(email: string, password: string) {
    const response = await fetch(API_HOST + `/api/v1/users/${email}/verify/`);

    if (response.status !== 200) {
      const { reason = 'Unknown error' } = (await response.json()) ?? {};
      throw new Error(reason);
    }

    const { user } = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    const apiClient = getApiClient();
    const { role, isLiftaiAdmin } = await apiClient.users.me();

    sessionHelper.setUserRole(role);
    sessionHelper.setIsLiftaiAdmin(isLiftaiAdmin);

    return user;
  },
  async loginUserWithEmailAndPassword(email: string, password: string) {
    const { user } = await signInWithEmailAndPassword(firebaseAuth, email, password);
    const apiClient = getApiClient();
    const { role, isLiftaiAdmin } = await apiClient.users.me();

    sessionHelper.setUserRole(role);
    sessionHelper.setIsLiftaiAdmin(isLiftaiAdmin);

    return user;
  },

  /**
   * `throw`s a `Response` if the user is not logged in. Otherwise, returns the
   * user.
   */
  async requireUser(
    redirectTo?: string,
    httpCode = 302,
  ): Promise<{
    userRole: UserRole;
    uid: string;
    email: string | undefined;
    isLiftaiAdmin: boolean;
  }> {
    const user = await getUser();

    if (!user) {
      throw redirect(`/login?${withLoginRedirectSearchParam(redirectTo)}`, httpCode);
    }

    const userRole = sessionHelper.getUserRole();
    const isLiftaiAdmin = sessionHelper.getIsLiftaiAdmin();

    // eAMP is unusable without a user role.
    if (!userRole) {
      throw new Error('User is not provisioned for eAMP');
    }

    return {
      userRole,
      uid: user.uid,
      email: user.email ?? undefined,
      isLiftaiAdmin,
    };
  },
});

export const liftAiAuth = () => {
  return authHelper(_sessionHelper);
};

let _apiClient: LiftAPIClient;

export const getApiClient = () => {
  if (!API_HOST) {
    throw new Error('API_HOST is not set');
  }

  if (!_apiClient) {
    _apiClient = new LiftAPIClient({
      authToken: defaultGetIdToken,
      fetch,
      apiHost: API_HOST,
    });
  }

  return _apiClient;
};

export const json: JSONFunction = reactRouterJson;
