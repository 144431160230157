import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Paper } from '@mui/material';
import type {
  DataGridPremiumProps,
  FilterColumnsArgs,
  GridColDef,
  GridToolbarProps,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import {
  DataGridPremium,
  GridLogicOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { type MutableRefObject } from 'react';

import type { LiftAIGridColDef } from './colDefTemplate';

export interface NestedKey {
  colName: string;
  queryKey: string;
}

type OmittedProps =
  | 'autoPageSize'
  | 'apiRef'
  | 'initialState'
  | 'columns'
  | 'pagination'
  | 'pageSize'
  | 'rowsPerPageOptions'
  | 'slots'
  | 'sx'
  | 'getDetailPanelHeight';

/**
 * Props for LiftAITable component
 * @param rows: Array of rows to be displayed in the table
 * @param columns: Array of columns to be displayed in the table
 * @param tableHeaderTitle: Title of the table
 */
interface LiftAITableProps<TRow extends GridValidRowModel>
  extends Exclude<DataGridPremiumProps<TRow>, OmittedProps> {
  columns: LiftAIGridColDef<TRow>[];
  tableHeaderTitle: string;
  quickFilterDebounceTime?: number;
  enableQuickFilter?: boolean;
  quickFilterPlaceHolder?: string;
  initialState?: DataGridPremiumProps['initialState'];
  /**
   * Pass `CustomGridToolbarExport` to override the export tool of the table.
   * This will replace the component entirely, allowing for fully customize UI and behavior.
   */
  CustomGridToolbarExport?: () => JSX.Element;
  /**
   * pass `CustomGridToolbarColumnsButton` to override the table columns preferences.
   * This will replace the component entirely, allowing for fully customize UI and behavior.
   */
  CustomGridToolbarColumnsButton?: () => JSX.Element;
  apiRef: MutableRefObject<GridApiPremium>;
  /**
   * Filters to preserve when clearing the filters.
   */
  filterToPreserve?: string[];
}

interface LiftAIToolbarProps extends GridToolbarProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function LiftAIToolbar({
  showQuickFilter,
  onChange,
  quickFilterPlaceHolder,
  showClearButton,
  onClearFilters,
  CustomGridToolbarExport,
  CustomGridToolbarColumnsButton,
}: LiftAIToolbarProps) {
  return (
    <Box display="flex" justifyContent="space-between">
      <GridToolbarContainer>
        {CustomGridToolbarColumnsButton ? (
          <CustomGridToolbarColumnsButton />
        ) : (
          <GridToolbarColumnsButton />
        )}
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {CustomGridToolbarExport ? <CustomGridToolbarExport /> : <GridToolbarExport />}
        {showClearButton ? (
          <Button
            sx={{ marginLeft: 2 }}
            size="small"
            startIcon={<ClearIcon />}
            onClick={onClearFilters}
            variant="outlined"
          >
            Clear Filters
          </Button>
        ) : null}
      </GridToolbarContainer>
      {showQuickFilter ? (
        <GridToolbarQuickFilter
          placeholder={quickFilterPlaceHolder}
          onChange={onChange}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1em',
              height: '1em',
              fontSize: '1.5rem',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            '& .MuiInputBase-root': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              padding: '5px',
            },
            '& .MuiInputBase-root:before': {
              borderBottom: 'none !important',
            },
            '& .MuiInputBase-root:after': {
              borderBottom: 'none !important',
            },
            '& .MuiInputBase-input': {
              marginLeft: '10px !important',
            },
          }}
        />
      ) : null}
    </Box>
  );
}

/**
 * Wrapper around DataGridPremium component to facilitate
 * most of the Pro features. Such as: Quick filtering of columns,
 * column reordering, column pinning, etc.
 * @param props: LiftAITableProps
 * @returns DataGridPremium component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function LiftAITable<TRow extends GridValidRowModel = any>({
  rows,
  columns,
  enableQuickFilter,
  quickFilterPlaceHolder,
  initialState,
  filterModel,
  onFilterModelChange,
  CustomGridToolbarExport,
  CustomGridToolbarColumnsButton,
  apiRef,
  filterToPreserve,
  ...tableProps
}: LiftAITableProps<TRow>) {
  const initialStateObj = useKeepGroupedColumnsHidden({
    apiRef,
    initialState,
  });

  const showClearButton =
    filterModel &&
    (filterModel.items.length > 0 ||
      (filterModel.quickFilterValues && filterModel.quickFilterValues?.length > 0));

  return (
    <Paper sx={{ padding: 1, flex: '1 1 auto', maxWidth: '100%' }}>
      <DataGridPremium
        apiRef={apiRef}
        initialState={initialStateObj}
        rows={rows}
        columns={columns}
        pagination
        slots={{
          toolbar: LiftAIToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: enableQuickFilter,
            showClearButton,
            CustomGridToolbarExport,
            CustomGridToolbarColumnsButton,
            onClearFilters: () => {
              if (onFilterModelChange) {
                const itemsToPreserve =
                  filterModel?.items.filter((item) => {
                    return filterToPreserve?.includes(item.field) || false;
                  }) ?? [];

                onFilterModelChange(
                  {
                    items: itemsToPreserve,
                    quickFilterValues: [],
                    quickFilterLogicOperator: GridLogicOperator.And,
                    logicOperator: GridLogicOperator.And,
                  },
                  { reason: 'deleteFilterItem' },
                );
              }
            },
            placeholder: quickFilterPlaceHolder,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              apiRef.current.setQuickFilterValues(event.target.value.split(' '));
            },
          },
          filterPanel: {
            // Force usage of "And" operator
            logicOperators: [GridLogicOperator.And],
            disableRemoveAllButton: true,
            filterFormProps: {
              /**
               * This is to remove the grouping column because it's duplicated
               *
               * Link: https://github.com/mui/mui-x/issues/12223
               */
              filterColumns: ({ columns }: FilterColumnsArgs) => {
                return columns
                  .filter((column) => column.type !== 'rowGroupByColumnsGroup')
                  .map((column) => column.field);
              },
            },
          },
          columnsPanel: {
            /**
             * TODO: If we change the MUI DataGrid Premium to the V7 we must change this.
             *
             * v6 => slotProps.columnsPanel.getTogglableColumns
             * v7 => slotProps.columnsManagement.getTogglableColumns
             */
            getTogglableColumns: (columns: GridColDef[]) => {
              return columns
                .filter((column) => column.type !== 'rowGroupByColumnsGroup')
                .map((column) => column.field);
            },
          },
        }}
        sx={{
          border: 0,
          '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders': {
            boxShadow: 'none',
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        {...tableProps}
      />
    </Paper>
  );
}
