import { styled } from '@mui/material';

import { invoiceDetailDrawerWidth } from '~/components/invoices/InvoiceDetailDrawerContext';

const Main = styled('main', {
  shouldForwardProp: (prop) => !['sidebarOpen', 'noPadding'].includes(prop as string),
})<{
  sidebarOpen?: boolean;
  noPadding?: boolean;
}>(({ theme, sidebarOpen, noPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  width: '100%',
  padding: noPadding ? 0 : theme.spacing(2),
  backgroundColor: '#F9FAFB',
  ...(sidebarOpen && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: invoiceDetailDrawerWidth,
    width: `calc(100% - ${invoiceDetailDrawerWidth})`,
  }),
}));

export default Main;
