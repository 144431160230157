import {
  API_AttachmentUploadRequest,
  API_CarsListResponse,
  API_ConsultantGroupDetailResponse,
  api_consultantGroupDetailSchema,
  API_InvoiceFilterSearchParams,
  API_InvoiceFilterSearchParamsInput,
  api_invoiceFormListResponse,
  API_InvoiceListResponse,
  API_InvoiceListVariant,
  api_invoiceTableListResponse,
  API_ListVariant,
  API_PortfolioKPIsResponse,
  API_PropertiesListResponse,
  API_PropertiesListVariant,
  API_SearchResponse,
  API_TicketFilterSearchParams,
  API_UserGroupDetailResponse,
  Attachment,
  attachmentSchema,
  carFormSchema,
  carInPropertySchema,
  carSchema,
  contractCustomDetailsSchema,
  contractSchema,
  DetermineEntryTypesRequest,
  DetermineProblemTypesRequest,
  invoiceAttachmentDetailSchema,
  InvoiceNote,
  invoiceSchema,
  portfolioClientSchema,
  PortfolioPendingDataSchema,
  propertyDisplaySchema,
  propertySchema,
  providerSchema,
  ticketAttachmentDetailSchema,
  ticketSchema,
  userSchema,
} from '@liftai/asset-management-types';
import { endOfDay, startOfDay } from 'date-fns';
import { z } from 'zod';

import { QueryParamsBuilder } from '~/utils/queryParams';

import { type ApiClientBase } from './types';

type Fetch = typeof fetch;

interface LiftAPIClientConfig {
  authToken(): Promise<string>;
  fetch: Fetch;
  apiHost: string;
  validateServerSchema?: boolean;
}

export class LiftAPIClient implements ApiClientBase {
  private config: LiftAPIClientConfig;

  constructor(config: LiftAPIClientConfig) {
    const { validateServerSchema = false } = config;
    this.config = { ...config, validateServerSchema };
  }

  private validateAndLogError<T extends z.ZodTypeAny>(
    schema: T,
    data: unknown,
    kind: string,
  ): z.output<T> {
    if (!this.config.validateServerSchema) {
      return data as T;
    }

    const valid = schema.safeParse(data);

    if (!valid.success) {
      console.error(
        `${kind} validation error:`,
        valid.error.errors.slice(0, 25).map(({ message, path }) => ({ message, path })),
      );
      return data as T;
    }

    return valid.data;
  }

  users: ApiClientBase['users'] = {
    /**
     *  `me` requests for the current user's profile information
     * @returns current user's profile
     */
    me: async () => {
      const response = await this.makeRequest({
        path: '/api/v1/me/',
        entityName: 'User',
      });

      const data = await response.json();

      return this.validateAndLogError(userSchema, data, 'User');
    },
  };

  portfolios: ApiClientBase['portfolios'] = {
    getAll: async <Variant extends API_ListVariant>({ variant = 'default' as Variant } = {}) => {
      const searchParams = new URLSearchParams();
      if (variant) {
        searchParams.set('variant', variant as string);
      }

      const path =
        '/api/v1/portfolios/' + (Array.from(searchParams.keys()).length ? '?' + searchParams : '');
      const response = await this.makeRequest({
        path,
        entityName: 'Portfolios',
      });

      const data = await response.json();

      return this.validateAndLogError(z.array(portfolioClientSchema), data, 'User');
    },
    filter: async ({ limit, offset, query, portfolios, serviceProviders, regions }) => {
      const params = new URLSearchParams();

      if (limit) {
        params.set('limit', limit.toString());
      }

      if (offset) {
        params.set('offset', offset.toString());
      }

      if (query) {
        params.set('query', query);
      }

      if (portfolios?.length) {
        for (const portfolio of portfolios) {
          params.append('portfolio', portfolio);
        }
      }

      if (serviceProviders?.length) {
        for (const serviceProvider of serviceProviders) {
          params.append('service_provider', serviceProvider);
        }
      }

      if (regions?.length) {
        for (const region of regions) {
          params.append('region', region);
        }
      }

      const response = await this.makeRequest({
        path: `/api/v1/portfolios/?${params.toString()}`,
        entityName: 'Portfolios',
      });

      const data = await response.json();

      return this.validateAndLogError(z.array(portfolioClientSchema), data, 'User');
    },
    kpis: async ({ startDate, endDate, propertyIds, portfolios, serviceProviders, regions }) => {
      const searchParams = QueryParamsBuilder.getRangeParameters(startDate, endDate);

      if (propertyIds?.length) {
        for (const propertyId of propertyIds) {
          searchParams.set('property', propertyId);
        }
      }

      if (portfolios?.length) {
        for (const portfolio of portfolios) {
          searchParams.append('portfolio', portfolio);
        }
      }

      if (serviceProviders?.length) {
        for (const serviceProvider of serviceProviders) {
          searchParams.append('service_provider', serviceProvider);
        }
      }

      if (regions?.length) {
        for (const region of regions) {
          searchParams.append('region', region);
        }
      }

      const response = await this.makeRequest({
        path: `/api/v1/portfolios/kpis/?${searchParams.toString()}`,
        entityName: 'Portfolio KPIs',
      });
      const data = await response.json();

      return this.validateAndLogError(API_PortfolioKPIsResponse, data, 'PortfolioKPIs');
    },
    kpisForProperty: async ({ startDate, endDate, propertyId }) => {
      const kpis = await this.portfolios.kpis({
        startDate,
        endDate,
        propertyIds: [propertyId],
      });
      return {
        byDate: kpis.byDate,
        summary: kpis.summary,
      };
    },
    pendingData: async ({ startDate, endDate, portfolios }) => {
      const searchParams = QueryParamsBuilder.getRangeParameters(startDate, endDate);
      if (portfolios?.length) {
        for (const portfolio of portfolios) {
          searchParams.append('portfolios', portfolio);
        }
      }

      const response = await this.makeRequest({
        path: `/api/v1/portfolios/pending-data?${searchParams}`,
        entityName: 'Portfolio Pending Data',
      });
      const data = await response.json();

      return this.validateAndLogError(
        z.array(PortfolioPendingDataSchema),
        data,
        'PortfolioPendingData',
      );
    },
  };

  invoices: ApiClientBase['invoices'] = {
    getAll: async <Variant extends API_InvoiceListVariant>({
      variant = 'default' as Variant,
    } = {}) => {
      const query = new QueryParamsBuilder().set('variant', variant).toString();
      const response = await this.makeRequest({
        path: '/api/v1/invoices/?' + query,
        entityName: 'Invoices',
      });

      const data = await response.json().catch((error) => {
        throw new Error(`Unable to fetch Invoices. ${error.message}`);
      });

      const schema =
        variant === 'form'
          ? api_invoiceFormListResponse
          : variant === 'table'
            ? api_invoiceTableListResponse
            : z.array(invoiceSchema);
      return this.validateAndLogError(schema, data, 'Invoice') as API_InvoiceListResponse<Variant>;
    },

    filter: async (unsafeParams: API_InvoiceFilterSearchParamsInput) => {
      const params = API_InvoiceFilterSearchParams.parse(unsafeParams);

      const queryParamsBuilder = new QueryParamsBuilder()
        .set('property', params.property)
        .set('date_start', params.startDate)
        .set('date_end', params.endDate)
        .set('query', params.query)
        .set('search', params.search)
        .set('portfolio', params.portfolio)
        .set('tickets', params.tickets)
        .set('service_provider', params.serviceProvider)
        .set('region', params.region)
        .set('date_stamped_start', params.startDateStamped)
        .set('date_stamped_end', params.endDateStamped)
        .set('number', params.number)
        .set('type', params.type)
        .set('kind', params.kind)
        .set('status', params.status)
        .set('proposed_amount', params.proposedAmount?.eq)
        .set('proposed_amount_gt', params.proposedAmount?.gt)
        .set('proposed_amount_gte', params.proposedAmount?.gte)
        .set('proposed_amount_lt', params.proposedAmount?.lt)
        .set('proposed_amount_lte', params.proposedAmount?.lte)
        .set('reviewed_amount', params.reviewedAmount?.eq)
        .set('reviewed_amount_gt', params.reviewedAmount?.gt)
        .set('reviewed_amount_gte', params.reviewedAmount?.gte)
        .set('reviewed_amount_lt', params.reviewedAmount?.lt)
        .set('reviewed_amount_lte', params.reviewedAmount?.lte)
        .set('savings', params.savings?.eq)
        .set('savings_gt', params.savings?.gt)
        .set('savings_gte', params.savings?.gte)
        .set('savings_lt', params.savings?.lt)
        .set('savings_lte', params.savings?.lte)
        .set('ordering', params.ordering)
        .set('limit', params.limit)
        .set('offset', params.offset)
        .set('variant', params.variant);

      const response = await this.makeRequest({
        path: '/api/v1/invoices/?' + queryParamsBuilder.toString(),
        entityName: 'Invoices',
      });
      const json = await response.json();

      // if pagination options are passed the API return the whole list of invoices but also metadata.
      if (queryParamsBuilder.get('limit')) {
        return {
          count: json.count,
          metadata: json.metadata,
          results: json.results,
        };
      }

      // if no pagination options are passed the API return the whole list of invoices directly.
      return {
        results: json,
        count: json.length,
        metadata: {},
      };
    },

    getById: async (id) => {
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${id}/`,
        entityName: 'Invoice',
      });
      const data = await response.json();

      return this.validateAndLogError(invoiceSchema, data, 'Invoice');
    },

    create: async (createData) => {
      const response = await this.makeRequest({
        path: '/api/v1/invoices/',
        entityName: 'Invoice',
        init: {
          method: 'POST',
          body: JSON.stringify(createData),
        },
      });

      const data = await response.json();
      return this.validateAndLogError(invoiceSchema, data, 'Invoice');
    },

    update: async (id, updateData) => {
      const stringifiedData = JSON.stringify(updateData);
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${id}/`,
        entityName: 'Invoice',
        init: {
          method: 'PATCH',
          body: stringifiedData,
        },
      });

      const data = await response.json();
      return this.validateAndLogError(invoiceSchema, data, 'Invoice');
    },

    delete: async (id) => {
      await this.makeRequest({
        path: `/api/v1/invoices/${id}/`,
        entityName: 'Invoice',
        init: {
          method: 'DELETE',
        },
      });
    },
    getAttachments: async (invoiceId) => {
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${invoiceId}/attachments/`,
        entityName: 'Invoice Attachments',
      });

      const data = await response.json();
      return this.validateAndLogError(
        z.array(invoiceAttachmentDetailSchema),
        data,
        'InvoiceAttachmentDetail',
      );
    },
    createAttachment: async (invoiceId, request) => {
      const payload = JSON.stringify({
        ...request,
      });
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${invoiceId}/attachments/`,
        entityName: 'Invoice Attachment',
        init: {
          method: 'POST',
          body: payload,
        },
      });
      const data = await response.json();
      return this.validateAndLogError(
        invoiceAttachmentDetailSchema,
        data,
        'InvoiceAttachmentDetail',
      );
    },
    updateAttachment: async (invoiceId, request) => {
      const payload = JSON.stringify({
        ...request,
      });
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${invoiceId}/attachments/${request.id}/`,
        entityName: 'Invoice Attachment',
        init: {
          method: 'PATCH',
          body: payload,
        },
      });
      const data = await response.json();
      return this.validateAndLogError(
        invoiceAttachmentDetailSchema,
        data,
        'InvoiceAttachmentDetail',
      );
    },
    deleteAttachment: async (id: string, attachmentId: string) => {
      await this.makeRequest({
        path: `/api/v1/invoices/${id}/attachments/${attachmentId}/`,
        entityName: 'Invoice Attachment',
        init: {
          method: 'DELETE',
        },
      });
    },

    getNotes: async (id) => {
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${id}/notes/`,
        entityName: 'Invoice Notes',
      });

      const data = await response.json();

      return data as InvoiceNote[];
    },

    addNote: async (invoiceId, consultantId, content) => {
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${invoiceId}/notes/`,
        entityName: 'Invoice Note',
        init: {
          method: 'POST',
          body: JSON.stringify({ invoice: invoiceId, author: consultantId, content }),
        },
      });

      const data = await response.json();

      return data as InvoiceNote;
    },
  };

  providers: ApiClientBase['providers'] = {
    filter: async ({ propertyId } = {}) => {
      const params = new URLSearchParams();

      if (propertyId) {
        params.set('property_id', propertyId);
      }

      const response = await this.makeRequest({
        path: '/api/v1/service-providers/?' + params.toString(),
        entityName: 'Provider',
      });
      const data = await response.json();

      return this.validateAndLogError(z.array(providerSchema), data, 'Provider');
    },
  };

  tickets: ApiClientBase['tickets'] = {
    getAll: async () => {
      const response = await this.makeRequest({
        path: '/api/v1/tickets/',
        entityName: 'Ticket',
      });
      const data = await response.json();

      return this.validateAndLogError(z.array(ticketSchema), data, 'Ticket');
    },
    filter: async (opts) => {
      const safeOpts = API_TicketFilterSearchParams.parse(opts);

      const {
        limit,
        offset,
        entryType,
        startedTimeAfter,
        startedTimeBefore,
        ordering,
        search,
        property,
        carId,
        propertyName,
        portfolios,
        serviceProviders,
        regions,
        number,
      } = safeOpts;

      const params = new URLSearchParams({
        ordering,
      });

      if (limit) {
        params.set('limit', limit.toString());
      }

      if (offset) {
        params.set('offset', offset.toString());
      }

      if (entryType) {
        for (const type of entryType) {
          params.append('entry_type', type);
        }
      }

      if (search) {
        params.set('search', search);
      }

      if (startedTimeAfter) {
        params.set('started_time_after', startOfDay(startedTimeAfter).toJSON());
      }

      if (startedTimeBefore) {
        params.set('started_time_before', endOfDay(startedTimeBefore).toJSON());
      }

      if (property) {
        params.set('property', property);
      }

      if (carId) {
        params.set('car_id', carId);
      }

      if (propertyName) {
        params.set('property_name', propertyName);
      }

      if (portfolios?.length) {
        for (const portfolio of portfolios) {
          params.append('portfolio', portfolio);
        }
      }

      if (serviceProviders?.length) {
        for (const serviceProvider of serviceProviders) {
          params.append('service_provider', serviceProvider);
        }
      }

      if (regions?.length) {
        for (const region of regions) {
          params.append('region', region);
        }
      }

      if (number) {
        params.set('number', number);
      }

      const response = await this.makeRequest({
        path: '/api/v1/tickets/?' + params.toString(),
        entityName: 'Ticket',
      });
      const json = await response.json();

      // if pagination options are passed the API return the whole list of tickets but also metadata.
      if (limit) {
        return {
          count: json.count,
          metadata: json.metadata,
          results: json.results,
        };
      }

      // if no pagination options are passed the API return the whole list of tickets directly.
      return {
        results: json,
        count: json.length,
        metadata: {},
      };
    },
    getById: async (id) => {
      const response = await this.makeRequest({
        path: `/api/v1/tickets/${id}/`,
        entityName: 'Ticket',
      });
      const data = await response.json();

      return this.validateAndLogError(ticketSchema, data, 'Ticket');
    },
    create: async (ticket) => {
      const response = await this.makeRequest({
        path: '/api/v1/tickets/',
        entityName: 'Ticket',
        init: {
          method: 'POST',
          body: JSON.stringify(ticket),
        },
      });

      const data = await response.json();
      return this.validateAndLogError(ticketSchema, data, 'Ticket');
    },
    update: async (ticket) => {
      const response = await this.makeRequest({
        path: `/api/v1/tickets/${ticket.id}/`,
        entityName: 'Ticket',
        init: {
          method: 'PATCH',
          body: JSON.stringify(ticket),
        },
      });

      const data = await response.json();
      return this.validateAndLogError(ticketSchema, data, 'Ticket');
    },
    getAttachments: async (ticketId) => {
      const response = await this.makeRequest({
        path: `/api/v1/invoices/${ticketId}/attachments/`,
        entityName: 'Ticket Attachments',
      });
      const data = await response.json();

      return this.validateAndLogError(
        z.array(ticketAttachmentDetailSchema),
        data,
        'TicketAttachmentDetail',
      );
    },
    createAttachment: async (ticketId, request) => {
      const payload = JSON.stringify({
        ...request,
      });
      const response = await this.makeRequest({
        path: `/api/v1/tickets/${ticketId}/attachments/`,
        entityName: 'Ticket Attachment',
        init: {
          method: 'POST',
          body: payload,
        },
      });
      const data = await response.json();
      return this.validateAndLogError(ticketAttachmentDetailSchema, data, 'TicketAttachmentDetail');
    },
    updateAttachment: async (ticketId, request) => {
      const payload = JSON.stringify({
        ...request,
      });
      const response = await this.makeRequest({
        path: `/api/v1/tickets/${ticketId}/attachments/${request.id}/`,
        entityName: 'Ticket Attachment',
        init: {
          method: 'PATCH',
          body: payload,
        },
      });
      const data = await response.json();
      return this.validateAndLogError(ticketAttachmentDetailSchema, data, 'TicketAttachmentDetail');
    },
    getByInvoiceId: async (invoiceId) => {
      const response = await this.makeRequest({
        path: `/api/v1/tickets/?invoice_id=${invoiceId}`,
        entityName: 'Ticket',
      });
      const data = await response.json();

      return this.validateAndLogError(z.array(ticketSchema), data, 'Ticket');
    },
    delete: async (id) => {
      await this.makeRequest({
        path: `/api/v1/tickets/${id}/`,
        entityName: 'Ticket',
        init: { method: 'DELETE' },
      });
    },

    deleteAttachment: async (id: string, attachmentId: string) => {
      await this.makeRequest({
        path: `/api/v1/tickets/${id}/attachments/${attachmentId}/`,
        entityName: 'Ticket Attachment',
        init: { method: 'DELETE' },
      });
    },
  };

  properties: ApiClientBase['properties'] = {
    getAll: async <Variant extends API_PropertiesListVariant>({
      variant = 'default' as Variant,
    } = {}) => {
      const searchParams = new URLSearchParams();

      let url = '/api/v1/properties/';

      if (variant) {
        searchParams.set('variant', variant as string);
        url += '?' + searchParams;
      }

      const response = await this.makeRequest({
        path: url,
        entityName: 'Properties',
      });
      const data = await response.json();

      const schema =
        variant === 'default' ? z.array(propertySchema) : z.array(propertyDisplaySchema);
      return this.validateAndLogError(
        schema,
        data,
        'Property',
      ) as API_PropertiesListResponse<Variant>;
    },

    getById: async (id) => {
      const response = await this.makeRequest({
        path: `/api/v1/properties/${id}/`,
        entityName: 'Property',
      });

      const data = await response.json();
      return this.validateAndLogError(propertySchema, data, 'Property');
    },
  };

  cars: ApiClientBase['cars'] = {
    filter: async ({ search, portfolioId, variant = 'default' as const } = {}) => {
      // TODO: The structure of the data returned from the API will be defined and
      // validated via the work in https://linear.app/liftai/issue/LAI-125/fetch-cars-api

      const searchParams = new URLSearchParams();
      if (search) {
        searchParams.set('search_cars', search);
      }

      if (variant) {
        searchParams.set('variant', variant as string);
      }

      if (portfolioId) {
        if (Array.isArray(portfolioId)) {
          for (const id of portfolioId) {
            searchParams.append('portfolio_id', id);
          }
        } else {
          searchParams.set('portfolio_id', portfolioId);
        }
      }

      const response = await this.makeRequest({
        path: '/api/v1/cars/' + (Array.from(searchParams.keys()).length ? '?' + searchParams : ''),
        entityName: 'Cars',
      });
      const data = await response.json();

      const schema = variant === 'default' ? z.array(carSchema) : z.array(carFormSchema);
      return this.validateAndLogError(schema, data, 'Car') as API_CarsListResponse<typeof variant>;
    },
    getByPropertyId: async ({ startDate, endDate, propertyId }) => {
      const searchParams = QueryParamsBuilder.getRangeParameters(startDate, endDate).toString();
      const response = await this.makeRequest({
        path: `/api/v1/cars/by-property-id/${propertyId}?${searchParams}`,
        entityName: 'Cars',
      });
      const data = await response.json();
      return this.validateAndLogError(z.array(carInPropertySchema), data, 'Car');
    },
  };

  contracts: ApiClientBase['contracts'] = {
    getAll: async () => {
      const response = await this.makeRequest({
        path: '/api/v1/contracts/',
        entityName: 'Contracts',
      });
      const data = await response.json();

      return this.validateAndLogError(z.array(contractSchema), data, 'Contract');
    },

    getByPropertyId: async (id) => {
      const response = await this.makeRequest({
        path: `/api/v1/contracts/?property=${id}`,
        entityName: 'Contracts',
      });
      const data = await response.json();
      return this.validateAndLogError(z.array(contractSchema), data, 'Contract');
    },

    updateSummary: async (id, summaryDetails) => {
      const response = await this.makeRequest({
        path: `/api/v1/contracts/${id}/summary-details/`,
        entityName: 'Contract Summary',
        init: {
          method: 'PATCH',
          body: JSON.stringify(summaryDetails),
        },
      });

      const data = await response.json();
      return this.validateAndLogError(contractSchema, data, 'Contract');
    },

    saveCustomSummary: async (id, summaryDetails) => {
      const response = await this.makeRequest({
        path: `/api/v1/contracts/${id}/custom-summary-details/`,
        entityName: 'Contract Custom Summary',
        init: {
          method: 'POST',
          body: JSON.stringify(summaryDetails),
        },
      });
      const data = await response.json();
      this.validateAndLogError(contractCustomDetailsSchema, data, 'Contract');
    },
  };

  consultants: ApiClientBase['consultants'] = {
    get: async (id: string): Promise<API_ConsultantGroupDetailResponse> => {
      const response = await this.makeRequest({
        path: `/api/v1/consultants/${id}/`,
        entityName: 'ConsultantGroup',
      });
      const data = await response.json();
      return this.validateAndLogError(api_consultantGroupDetailSchema, data, 'ConsultantGroup');
    },
  };

  clients: ApiClientBase['clients'] = {
    get: async (id: string): Promise<API_UserGroupDetailResponse> => {
      const response = await this.makeRequest({
        path: `/api/v1/clients/${id}/`,
        entityName: 'ClientGroup',
      });
      const data = await response.json();

      return this.validateAndLogError(api_consultantGroupDetailSchema, data, 'ClientGroup');
    },
  };

  search: ApiClientBase['search'] = async (query) => {
    const response = await this.makeRequest({
      path: `/api/v1/search/?query=${query}`,
      entityName: 'Search',
    });
    const data = await response.json();

    return data as API_SearchResponse;
  };

  attachments: ApiClientBase['attachments'] = {
    upload: async (params: API_AttachmentUploadRequest): Promise<Attachment> => {
      const formData = new FormData();
      formData.append('file', params.file);
      formData.append('name', params.name);
      formData.append('file_type', params.file_type);

      const response = await this.makeRequest({
        path: '/api/v1/attachments/',
        entityName: 'Attachment',
        init: {
          method: 'POST',
          body: formData,
          redirect: 'follow',
        },
      });

      const data = await response.json();
      return this.validateAndLogError(attachmentSchema, data, 'Attachment');
    },
  };

  tools: ApiClientBase['tools'] = {
    determineProblemTypes: async (params: DetermineProblemTypesRequest) => {
      const response = await this.makeRequest({
        path: '/api/v1/tickets/determine-problem-type/',
        entityName: 'Ticket',
        init: {
          method: 'POST',
          body: JSON.stringify({
            tickets: params.tickets,
          }),
        },
      });
      return response.json();
    },

    determineEntryTypes: async (params: DetermineEntryTypesRequest) => {
      const response = await this.makeRequest({
        path: '/api/v1/tickets/determine-entry-type/',
        entityName: 'Ticket',
        init: {
          method: 'POST',
          body: JSON.stringify({
            tickets: params.tickets,
          }),
        },
      });
      return response.json();
    },
  };

  protected async makeRequest({
    path,
    entityName,
    init = {},
  }: {
    path: string;
    entityName: string;
    init?: Omit<RequestInit, 'headers'> & { headers?: Record<string, string> };
  }) {
    const { authToken, apiHost, fetch } = this.config;

    const headers: Record<string, string> = {
      Authorization: `Bearer ${await authToken()}`,
      ...init.headers,
    };

    if (!(init.body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    const resp = await fetch(`${apiHost}${path}`, {
      method: 'GET',
      ...init,
      headers,
    });

    if (!resp.ok) {
      throw new Error(this.getErrorMessage(init.method ?? 'GET', entityName), { cause: resp });
    }

    return resp;
  }

  private getErrorMessage(verb: string, entityName: string) {
    switch (verb) {
      case 'GET':
        return `Error fetching ${entityName.toLowerCase()}`;
      case 'POST':
        return `Error creating ${entityName.toLowerCase()}`;
      case 'PATCH':
        return `Error updating ${entityName.toLowerCase()}`;
      case 'DELETE':
        return `Error deleting ${entityName.toLowerCase()}`;
      default:
        return `Error ${verb} ${entityName.toLowerCase()}`;
    }
  }
}
