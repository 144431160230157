import type {
  InvoiceStatusKey,
  InvoiceTypeKey,
  InvoiceWithPortfolio,
  InvoiceWithTickets,
} from '@liftai/asset-management-types';
import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

import { gridFilterItemToNumberFilterParams } from '~/components/table/helpers';
import useInvoices, { type UseInvoicesParameters } from '~/data/hooks/useInvoices';
import { getApiClient } from '~/utils/api';

export const invoiceWithPortfolio = (invoice: InvoiceWithTickets): InvoiceWithPortfolio => {
  return {
    ...invoice,
    portfolio: invoice.property.portfolio!,
  };
};

const selectedPeriodRangeAfterId = 'selected-period-range-after';
const selectedPeriodRangeBeforeId = 'selected-period-range-before';

export const getFilterModelFromQueryParams = (searchParams: URLSearchParams): GridFilterModel => {
  const items: GridFilterModel['items'] = [];

  const proposedAmount = searchParams.get('proposed_amount');
  if (proposedAmount) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '=',
      value: proposedAmount,
    });
  }

  const proposedAmountGreaterThan = searchParams.get('proposed_amount_gt');
  if (proposedAmountGreaterThan) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '>',
      value: proposedAmountGreaterThan,
    });
  }

  const proposedAmountGreaterThanEqual = searchParams.get('proposed_amount_gte');
  if (proposedAmountGreaterThanEqual) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '>=',
      value: proposedAmountGreaterThanEqual,
    });
  }

  const proposedAmountLessThan = searchParams.get('proposed_amount_lt');
  if (proposedAmountLessThan) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '<',
      value: proposedAmountLessThan,
    });
  }

  const proposedAmountLessThanEqual = searchParams.get('proposed_amount_lte');
  if (proposedAmountLessThanEqual) {
    items.push({
      id: 'proposed_amount',
      field: 'proposedAmount',
      operator: '<=',
      value: proposedAmountLessThanEqual,
    });
  }

  const startDate = searchParams.get('start_date');
  if (startDate) {
    items.push({
      id: selectedPeriodRangeAfterId,
      field: 'date',
      value: format(new Date(startDate), 'yyyy-MM-dd'),
      operator: 'onOrAfter',
    });
  }

  const endDate = searchParams.get('end_date');
  if (endDate) {
    items.push({
      id: selectedPeriodRangeBeforeId,
      field: 'date',
      value: format(new Date(endDate), 'yyyy-MM-dd'),
      operator: 'onOrBefore',
    });
  }

  const portfolios = searchParams.getAll('portfolio');
  if (portfolios) {
    for (const portfolio of portfolios) {
      items.push({
        id: 'portfolio',
        field: 'portfolio',
        operator: 'contains',
        value: portfolio,
      });
    }
  }

  const region = searchParams.getAll('region');
  if (region) {
    for (const r of region) {
      items.push({
        id: 'region',
        field: 'region',
        operator: 'contains',
        value: r,
      });
    }
  }

  const number = searchParams.get('number');
  if (number) {
    items.push({
      id: 'number',
      field: 'number',
      operator: 'equals',
      value: number,
    });
  }

  const serviceProviders = searchParams.getAll('service_provider');
  if (serviceProviders) {
    for (const serviceProvider of serviceProviders) {
      items.push({
        id: 'serviceProvider',
        field: 'serviceProvider',
        operator: 'contains',
        value: serviceProvider,
      });
    }
  }

  const type = searchParams.getAll('invoice_type') as InvoiceTypeKey[];
  if (type.length > 0) {
    items.push({
      id: 'type',
      field: 'type',
      operator: 'isAnyOf',
      value: type,
    });
  }

  const status = searchParams.getAll('invoice_status') as InvoiceStatusKey[];
  if (status.length > 0) {
    items.push({
      id: 'status',
      field: 'status',
      value: status,
      operator: 'isAnyOf',
    });
  }

  const property = searchParams.get('property');
  if (property) {
    items.push({
      id: 'property',
      field: 'property',
      operator: 'equals',
    });
  }

  const propertyName = searchParams.get('property_name');
  if (propertyName) {
    items.push({
      id: 'property-name',
      field: 'property',
      operator: 'contains',
      value: propertyName,
    });
  }

  return { items };
};

type BuildFilterParamsOpts = {
  propertyId: string | null;
  page: number;
  pageSize: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  searchParams: URLSearchParams;
};

const buildFilterParams = ({
  propertyId,
  page,
  pageSize,
  filterModel,
  sortModel,
  searchParams,
}: BuildFilterParamsOpts): UseInvoicesParameters | undefined => {
  if (pageSize <= 0 || page < 0) {
    return undefined;
  }

  return {
    limit: pageSize.toString(),
    offset: (page * pageSize).toString(),
    ordering:
      sortModel.length > 0
        ? sortModel.map((item) => `${item.sort === 'desc' ? '-' : ''}${item.field}`).join(',')
        : '-date',
    search: filterModel.quickFilterValues?.map((value) => value).join(' '),
    portfolio: searchParams.getAll('portfolio'),
    serviceProvider: searchParams.getAll('service_provider'),
    kind: searchParams.getAll('invoice_kind'),
    region: searchParams.getAll('region'),
    startDate: searchParams.get('start_date') ?? undefined,
    endDate: searchParams.get('end_date') ?? undefined,
    number: filterModel.items.find((item) => item.field === 'number')?.value,
    tickets: filterModel.items.find((item) => item.field === 'tickets')?.value,
    type: filterModel.items.find((item) => item.field === 'type')?.value,
    status: filterModel.items.find((item) => item.field === 'status')?.value,
    propertyName: filterModel.items.find((item) => item.field === 'property')?.value,
    property: propertyId ?? undefined,
    proposedAmount: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'proposedAmount'),
    ),
    reviewedAmount: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'reviewedAmount'),
    ),
    savings: gridFilterItemToNumberFilterParams(
      filterModel.items.filter((item) => item.field === 'savings'),
    ),
    startDateStamped: filterModel.items.find(
      (item) => item.field === 'dateStamped' && item.operator === 'onOrAfter',
    )?.value,
    endDateStamped: filterModel.items.find(
      (item) => item.field === 'dateStamped' && item.operator === 'onOrBefore',
    )?.value,
  };
};

export type UseSpendingOptions = {
  propertyId?: string | null;
  paginationModel: GridPaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
};

export function useSpending({
  propertyId = null,
  paginationModel,
  filterModel,
  sortModel,
}: UseSpendingOptions) {
  const [searchParams] = useSearchParams();

  const filterParams = useMemo(
    () =>
      buildFilterParams({
        propertyId,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        filterModel,
        sortModel,
        searchParams,
      }),
    [propertyId, paginationModel, filterModel, sortModel, searchParams],
  );

  const [filterParamsDebounced] = useDebounceValue(filterParams, 250);

  const { invoices: data, metadata, isLoading } = useInvoices(filterParamsDebounced);

  const onExport = useCallback(async () => {
    const apiClient = getApiClient();
    const { results } = await apiClient.invoices.filter({
      ...filterParams,
      limit: undefined,
      offset: undefined,
    });
    return results.map((result) => invoiceWithPortfolio(result));
  }, [filterParams]);

  const invoices = data?.map((invoice) => invoiceWithPortfolio(invoice)) ?? [];
  const numInvoices = metadata?.numInvoices ?? 0;

  return {
    invoices,
    numInvoices,
    metadata,
    isLoading,
    onExport,
  };
}
