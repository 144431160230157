import { MenuItem } from '@mui/material';
import type { GridColDef, GridExportExtension } from '@mui/x-data-grid-premium';
import {
  gridColumnDefinitionsSelector,
  gridColumnLookupSelector,
  GridExcelExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { format } from 'date-fns';
import type Excel from 'exceljs';
import { json2csv } from 'json-2-csv';
import { forOwn, get, isArray, isPlainObject, map, pick, set } from 'lodash-es';
import { closeSnackbar } from 'notistack';
import type { MutableRefObject } from 'react';
import { useCallback, useContext } from 'react';

import { UserContext } from '~/auth/userContext';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { formatCurrency } from '~/utils/format';

type ExportDataAs<U> = {
  apiRef: MutableRefObject<GridApiPremium>;
  data: U[];
  useHeaderName?: boolean;
  currencyFields?: string[];
  currencyCode: string;
};

/**
 * taken borrowed from mui-x
 * @link [exportAs](https://github.com/mui/mui-x/blob/next/packages/x-data-grid/src/utils/exportAs.ts#L14)
 */
export const exportAs = (
  blob: Blob,
  extension: GridExportExtension = 'csv',
  filename: string = document.title || 'untitled',
): void => {
  const fullName = `${filename}.${extension}`;
  if ('download' in HTMLAnchorElement.prototype) {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fullName;

    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
    return;
  }
};

/**
 * `getColumnsToExport` returns an array of
 * the column field names that should be exported
 */
const getColumnsToExport = (apiRef: MutableRefObject<GridApiPremium>) => {
  return apiRef.current
    .getAllColumns()
    .map((col) => col.field)
    .filter((field) => field !== 'actions' && field !== '__detail_panel_toggle__');
};

type SerializedColumn = {
  key: string;
  width: number;
  style: Partial<Excel.Style>;
  headerText: string;
};

const serializeColumn = (column: GridColDef): SerializedColumn => {
  const defaultColumnsStyles: { [key: string]: Partial<Excel.Style> } = {
    date: { numFmt: 'dd.mm.yyyy' },
    dateTime: { numFmt: 'dd.mm.yyyy hh:mm' },
  };
  const { field, type } = column;

  return {
    key: field,
    headerText: column.headerName ?? column.field,
    // Excel width must stay between 0 and 255 (https://support.microsoft.com/en-us/office/change-the-column-width-and-row-height-72f5e3cc-994d-43e8-ae58-9774a0905f46)
    // From the example of column width behavior (https://docs.microsoft.com/en-US/office/troubleshoot/excel/determine-column-widths#example-of-column-width-behavior)
    // a value of 10 corresponds to 75px. This is an approximation, because column width depends on the font-size
    width: Math.min(255, column.width ? column.width / 7.5 : 8.43),
    style: { ...(type && defaultColumnsStyles[type]) },
  };
};

const serializeColumns = (columns: GridColDef[]): Array<SerializedColumn> => {
  return columns.map((column) => serializeColumn(column));
};

const prepareForExport = async <T extends Object>({
  apiRef,
  data,
  useHeaderName = false,
  currencyFields = [],
  currencyCode,
}: ExportDataAs<T>): Promise<Partial<T>[]> => {
  const fieldsToExport = getColumnsToExport(apiRef);
  const columns = gridColumnLookupSelector(apiRef);

  return data.map((datum) => {
    let rowToExport = pick(datum, fieldsToExport);
    forOwn(rowToExport, (value) => {
      if (isPlainObject(value)) {
        rowToExport = { ...rowToExport, ...pick(value, fieldsToExport) };
      }
    });

    const record: Partial<T> = {};
    forOwn(rowToExport, (value, key) => {
      const header = columns[key][useHeaderName ? 'headerName' : 'field'] as string;
      const type = columns[key].type;

      if (!value) {
        set(record, header, '');
      } else if (isArray(value)) {
        set(record, header, map(value, 'name').join(', '));
      } else if (isPlainObject(value)) {
        set(record, header, get(value, 'name', get(value, 'number', '')));
      } else {
        if (currencyFields.includes(key)) {
          // Handle currency fields with proper type checking
          const numValue = typeof value === 'string' ? parseFloat(value) : Number(value);
          if (!Number.isNaN(numValue)) {
            if (!currencyCode) {
              console.warn(`Currency code not provided for field ${key}`);
              set(record, header, value);
              return;
            }
            const formattedValue = formatCurrency({
              value: numValue,
              currencyCode,
            });
            set(record, header, formattedValue);
          } else {
            set(record, header, value);
          }
        } else if (type === 'date') {
          set(record, header, format(new Date(value as string), 'MM.dd.yyyy'));
        } else if (type === 'dateTime') {
          set(record, header, format(new Date(value as string), 'MM/dd/yyyy hh:mm:ss a'));
        } else if (type === 'number') {
          set(record, header, parseFloat(parseFloat(value as string).toFixed(2)));
        } else {
          set(record, header, value);
        }
      }
    });

    return record;
  });
};

const exportDataAsCsv = async ({
  apiRef,
  data,
  currencyFields,
  currencyCode,
}: ExportDataAs<Object>) => {
  const exportedRecords = await prepareForExport({
    apiRef,
    data,
    useHeaderName: true,
    currencyFields,
    currencyCode,
  });

  const csv = json2csv(exportedRecords);
  const blob = new Blob(['', csv], {
    type: 'text/csv',
  });

  exportAs(blob, 'csv');
};

const exportDataAsExcel = async ({
  apiRef,
  data,
  currencyFields,
  currencyCode,
}: ExportDataAs<Object>) => {
  const exportedRecords = await prepareForExport({ apiRef, data, currencyFields, currencyCode });

  const Excel = await import('exceljs');

  // initialize excel
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // prepare columns
  const columnsToExport = getColumnsToExport(apiRef);
  const columnDefinitions = gridColumnDefinitionsSelector(apiRef).filter((col) =>
    columnsToExport.includes(col.field),
  );
  worksheet.columns = serializeColumns(columnDefinitions);

  // add header
  worksheet.addRow(columnDefinitions.map((column) => column.headerName));

  exportedRecords.map((record) => worksheet.addRow(record));

  // export as xls
  const xls = await workbook.xlsx.writeBuffer();
  const blob = new Blob([xls], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  exportAs(blob, 'xlsx');
};

export type UseGridExporter<U> = {
  fetcher: () => Promise<U[]>;
  apiRef: MutableRefObject<GridApiPremium>;
  onStartExport?: () => void;
  onFinishedExport?: () => void;
  onErrorExport?: () => void;
  currencyFields?: string[];
};
export const useGridExporter = <T extends object>({
  fetcher,
  apiRef,
  onStartExport,
  onFinishedExport,
  onErrorExport,
  currencyFields = [],
}: UseGridExporter<T>) => {
  const { showEntityActionSnackbar } = useLAISnackbar();
  const { currencyCode } = useContext(UserContext);

  const onStartExportDefault = useCallback(() => {
    showEntityActionSnackbar(
      {
        name: 'Exporting information...',
        action: ActionEnum.StatusChange,
      },
      {
        variant: 'info',
        persist: true,
      },
    );
  }, [showEntityActionSnackbar]);

  const onFinishedExportDefault = useCallback(() => {
    closeSnackbar();
    showEntityActionSnackbar(
      {
        name: 'Information exported successfully',
        action: ActionEnum.StatusChange,
      },
      {
        variant: 'success',
        autoHideDuration: 5000,
      },
    );
  }, [showEntityActionSnackbar]);

  const onErrorExportDefault = useCallback(() => {
    closeSnackbar();
    showEntityActionSnackbar(
      {
        name: 'Exporting information failed',
        action: ActionEnum.Fail,
      },
      {
        variant: 'error',
        autoHideDuration: 5000,
      },
    );
  }, [showEntityActionSnackbar]);

  /**
   * Custom GridToolbarExport
   */
  const GridToolbarExport = useCallback(
    () => (
      <GridToolbarExportContainer>
        <MenuItem
          onClick={async () => {
            try {
              onStartExport ? onStartExport() : onStartExportDefault();

              const data = await fetcher();
              if (!data) {
                return;
              }

              await exportDataAsCsv({ apiRef, data, currencyFields, currencyCode });

              onFinishedExport ? onFinishedExport() : onFinishedExportDefault();
            } catch (error) {
              console.error('Error exporting data as CSV:', error);
              onErrorExport ? onErrorExport() : onErrorExportDefault();
            }
          }}
        >
          Download as CSV
        </MenuItem>
        <GridExcelExportMenuItem
          onClick={async () => {
            try {
              onStartExport ? onStartExport() : onStartExportDefault();

              const data = await fetcher();
              if (!data) {
                return;
              }

              await exportDataAsExcel({ apiRef, data, currencyFields, currencyCode });

              onFinishedExport ? onFinishedExport() : onFinishedExportDefault();
            } catch (error) {
              console.error('Error exporting data as CSV:', error);
              onErrorExport ? onErrorExport() : onErrorExportDefault();
            }
          }}
        />
        <GridPrintExportMenuItem />
      </GridToolbarExportContainer>
    ),
    [
      apiRef,
      fetcher,
      currencyFields,
      currencyCode,
      onErrorExport,
      onErrorExportDefault,
      onFinishedExport,
      onFinishedExportDefault,
      onStartExport,
      onStartExportDefault,
    ],
  );

  return {
    GridToolbarExport,
    onFinishedExportDefault,
    onErrorExportDefault,
    onStartExportDefault,
  };
};
