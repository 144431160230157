import { isInvoice } from '@liftai/asset-management-types';
import { captureException } from '@sentry/react';
import { useCallback, useMemo, useState } from 'react';

import LAIConfirmationDialog from '~/components/dialogs/LAIConfirmationDialog';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

// -------------------------------------------------------------------------------------------------
// Component

interface DeleteEntityDialogProps<T> {
  /** The Entity to be potentially deleted. */
  entity: T;
  /** Name of the entity to delete */
  entityName: string;
  /** A handler for when the Entity was confirmed for deletion. */
  onDelete(): void;
  /** A handler for when the dialog requests closure. Called regardless of outcome. */
  onClose(): void;
}

function DeleteEntityDialog<T>({
  onDelete,
  onClose,
  entityName,
  entity,
}: DeleteEntityDialogProps<T>) {
  if (isInvoice(entity)) {
    return (
      <LAIConfirmationDialog
        open
        title={`Delete ${entity.kind}`}
        acceptButtonColor="error"
        acceptButtonText="Delete"
        onAccept={() => {
          onDelete();
          onClose();
        }}
        onClose={onClose}
      >
        {`Are you sure you want to delete this ${entity.kind}?`}
      </LAIConfirmationDialog>
    );
  }

  return (
    <LAIConfirmationDialog
      open
      title={`Delete ${entityName}`}
      acceptButtonColor="error"
      acceptButtonText="Delete"
      onAccept={() => {
        onDelete();
        onClose();
      }}
      onClose={onClose}
    >
      {`Are you sure you want to delete this ${entityName}?`}
    </LAIConfirmationDialog>
  );
}

// -------------------------------------------------------------------------------------------------
// Hook

interface UseDeleteEntityDialogParams<T> {
  onDelete: (entity: T) => void | Promise<void>;
  entityName: string;
}

export function useDeleteEntityDialog<T>({ onDelete, entityName }: UseDeleteEntityDialogParams<T>) {
  const [activeEntity, setActiveEntity] = useState<T>();
  const closeDialog = useCallback(() => {
    setActiveEntity(undefined);
  }, []);
  const { showEntityActionSnackbar } = useLAISnackbar();

  const dialog = useMemo(
    () =>
      activeEntity ? (
        <DeleteEntityDialog<T>
          entity={activeEntity}
          entityName={entityName}
          onDelete={async () => {
            try {
              await onDelete(activeEntity);
            } catch (err) {
              captureException(err);
              showEntityActionSnackbar(
                {
                  name: entityName,
                  action: ActionEnum.Fail,
                  id: undefined,
                },
                {
                  variant: 'error',
                },
              );
            }
          }}
          onClose={closeDialog}
        />
      ) : null,
    [activeEntity, onDelete, entityName, closeDialog, showEntityActionSnackbar],
  );

  return {
    /** The dialog as a JSX.Element or `null`, if it shouldn't be shown. */
    dialog,
    /** Opens the dialog for a specified `Entity` instance. */
    openForEntity: useCallback((entity: T) => {
      setActiveEntity(entity);
    }, []),
    /** Closes the dialog imperatively rather than waiting for it to request it. */
    closeDialog,
  };
}
